import React from "react";
import { Fragment } from "react";
import ArticleBanner from "../Components/Articel/ArticleBanner";

function Newsnevents() {
    return (
        <Fragment>
            <div className="container-fluid faderight ">
                <div className="row">
                    <ArticleBanner />
                </div>
            </div>
        </Fragment>
    );
}

export default Newsnevents;
