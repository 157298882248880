import React from "react";
import { Fragment } from "react";

import Fade from "react-reveal/Fade";
import { Table } from "antd";

function FMRTable() {
  const columns = [
    {
      title: "Fund Manger Reports",
      dataIndex: "name",
    },
    // {
    //   title: "Offer Price (Rs.)",
    //   dataIndex: "offerPrice",
    // },
    {
      title: "Details",
      dataIndex: "headtonav",
    },
  ];
  const data = [
    {
      key: "1",
      name: "SFTL Fund Manager Report June 2024",
      headtonav: (
        <a
          href={`${process.env.REACT_APP_ASSETS_URL}Assets/pdf/FmrJune.pdf`}
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          View Detail
        </a>
      ),
    },
    {
      key: "2",
      name: "SFTL Fund Manager Report July 2024",
      headtonav: (
        <a
          href={`${process.env.REACT_APP_ASSETS_URL}Assets/pdf/FmrJuly.pdf`}
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          View Detail
        </a>
      ),
    },
    {
      key: "3",
      name: "SFTL Fund Manager Report August 2024",
      headtonav: (
        <a
          href={`${process.env.REACT_APP_ASSETS_URL}Assets/pdf/fmrAugust.pdf`}
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          View Detail
        </a>
      ),
    },
    {
      key: "4",
      name: "SFTL Fund Manager Report September 2024",
      headtonav: (
        <a
          href={`${process.env.REACT_APP_ASSETS_URL}Assets/pdf/fmrSeptember.pdf`}
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          View Detail
        </a>
      ),
    },
    {
      key: "5",
      name: "SFTL Fund Manager Report October 2024",
      headtonav: (
        <a
          href={`${process.env.REACT_APP_ASSETS_URL}Assets/pdf/fmrOctober.pdf`}
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          View Detail
        </a>
      ),
    },
  ];

  return (
    <Fragment>
      <div className="sub complainforms whistleforms fundPrices">
        <div className="row">
          <div className="col-sm-12 formstyle rightform">
            <Fade down delay={500}>
              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                bordered
                summary={(pageData) => {
                  let totalBorrow = 0;
                  let totalRepayment = 0;
                  pageData.forEach(({ borrow, repayment }) => {
                    totalBorrow += borrow;
                    totalRepayment += repayment;
                  });
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={1}></Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            </Fade>
          </div>
        </div>
        {/* Display success message at the bottom */}
      </div>
    </Fragment>
  );
}

export default FMRTable;
