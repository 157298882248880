export const menu = [
  {
    title: "About Us",
    image: "../asset/slide1.PNG",
  },
];

export const Articles = [
  {
    key: "1",
    feature: `${process.env.REACT_APP_ASSETS_URL}Assets/img/Article/LifelongLesson.png`,
    heading: 'The Importance of Saving: A Lifelong Lesson',
    content: 'From an early age, we are taught the importance of saving. Whether it’s a piggy bank as a child or a savings account as an adult, the act of setting aside money for the future is ingrained in our financial habits. The rationale is simple: saving provides security, allows us to plan for big purchases or emergencies, and ensures that we’re prepared for an uncertain future. But in a world with ever-rising costs and financial volatility, saving becomes even more critical. Without a strong savings foundation, individuals are left vulnerable to sudden financial shocks, and, in a broader context, the entire economy can falter.',
    content1: 'In this challenging landscape, Salaam Family Takaful Limited has arrived to transform how Pakistanis manage their savings and investments. As the first-ever digital life Takaful operator, Salaam Family Takaful is revolutionizing the insurance industry with a 100% Shariah-compliant and fully digital product, ‘Salaam Life and Savings’. Unlike traditional insurance products, Salaam Life and Savings offers instant access and transparency, allowing you to break even from day one with 100% allocation of your funds from the start. Their comprehensively digital platform simplifies the process—whether it’s hassle-free withdrawals, a fully online purchase experience, or returns based on ethical investing principles, the solution not only meets, but exceeds the demands of modern savers. ',
    content2: "<p>On a personal level, savings allow individuals to secure their financial future, plan for long-term goals, and cope with unforeseen expenses. For Pakistan, where inflation surged to 33.5% by mid-2024 (<a href=\"https://arynews.tv/pakistans-inflation-drops-to-lowest-level-in-two-years/\" target=\"_blank\">ARY News</a>; <a href=\"https://propakistani.pk/2024/05/02/pakistans-inflation-drops-to-lowest-level-in-2-years/\" target=\"_blank\">ProPakistani</a>), the importance of savings has never been more evident. The gross savings rate in Pakistan fell from 5.607% to 3.610 in 2022 but is on an upward trajectory again in 2024, with a percentage of 6.85 marking a recovery from earlier declines (<a href=\"https://www.ceicdata.com/en/indicator/pakistan/gross-savings-rate\" target=\"_blank\">CEIC Data</a>; <a href=\"https://www.undp.org/sites/g/files/zskgke326/files/2023-07/Quarterly%20economic%20profile_FINAL_July%202023.pdf\" target=\"_blank\">UNDP Quarterly Report</a>). In contrast, neighbouring countries such as India and Bangladesh maintain much higher gross savings rates, with India at 30% and Bangladesh at 24.9%, demonstrating their resilience against inflationary pressures (<a href=\"https://www.brecorder.com/news/40301493\" target=\"_blank\">Business Recorder</a>).</p>",
    content3: "This broader economic instability reflects Pakistan's reliance on informal saving mechanisms like gold or real estate. This economic reality makes Salaam Family Takaful's Salaam Life & Savings more crucial than ever. While traditional saving options have their own shortcomings, Salaam Life & Savings stands out by offering a unique blend of life protection and return on investment. Not only does it safeguard your financial future by offering a life Takaful plan, but it also provides alternate income stream, allowing savers to grow their wealth in a completely Shariah-compliant manner.",
    content4: "With no hidden fees, 100% transparency, and the ability to break even from day one, it outshines existing products in the market. The universality of Salaam Life & Savings is such that it caters to all the savings requirements with flexible investment options, ensuring that every individual, from young professionals to those planning for retirement, can find a plan that aligns with their financial goals. As inflation erodes the value of conventional savings, Salaam Family Takaful's product not only protects wealth but also allows individuals to grow it, ensuring that they stay ahead of rising costs and unforeseen circumstances. This dual benefit of life protection and a profitable savings plan which provides returns on a daily basis and easy withdrawals from day 1, puts Salaam Life & Savings in a league of its own.",
    content5: "Saving is more than just a financial habit—it’s a crucial step towards building a stable and secure future. In times of rising inflation and economic uncertainty, the need for transparent, Shariah-compliant savings solutions is more critical than ever. Salaam Family Takaful's Salaam Life & Savings meets this need by offering a unique combination of life protection and a powerful investment avenue. With features like break even from day one and 100% growth of wealth on shariah guideliens, it is designed to help modern savers stay ahead of financial challenges and secure their future with confidence.",
    heading1: 'Salaam Family Takaful: Revolutionizing Savings and Investments',
    heading2: 'The Current Economic Reality and Why Salaam Life & Savings is Essential',
    // links: [
    //   "https://arynews.tv/pakistans-inflation-drops-to-lowest-level-in-two-years/",
    //   "https://propakistani.pk/2024/05/02/pakistans-inflation-drops-to-lowest-level-in-2-years/",
    //   "https://www.brecorder.com/news/40301493",
    //   "https://www.ceicdata.com/en/indicator/pakistan/gross-savings-rate",
    //   "https://www.undp.org/sites/g/files/zskgke326/files/2023-07/Quarterly%20economic%20profile_FINAL_July%202023.pdf"    
    // ]
  },
  {
    key: "2",
    feature: `${process.env.REACT_APP_ASSETS_URL}Assets/img/Article/LifeInsurance.jpeg`,
    heading:"A Shariah-Compliant Alternative to Traditional Life Insurance: Salaam Family Takaful – Your Superior Choice for Savings & Protection",
    content:"Salaam Family Takaful Limited, first ever digital life takaful operator, offers a 100% Halal alternative to traditional life insurance, through Salaam Life & Savings, designed to secure your financial future while adhering to Islamic principles. What sets Salaam Life & Savings, our revolutionary offering, stand out, is its 100% investment allocation from Day 1, meaning every contribution is fully invested from the start, ensuring immediate growth of your savings.",
    content1: "You can imagine how immaculate it is to enjoy financial growth without the worries of your invested amount, which is available to you all the time. With easy withdrawals, you can access your funds anytime through a simple, hassle-free process, and that too at no additional cost.",
    content2: "Salaam Family Takaful operates within a Shariah-compliant framework, avoiding interest (riba), gambling (maysir), and prohibited investments. Instead, it follows a model of mutual cooperation where participants contribute to a collective fund, ensuring that your financial decisions are aligned with Islamic values.",
    content3: "All investments are made in Shariah-compliant portfolios, free from industries that conflict with Islamic principles. Transparency is central to every transaction, and Salaam Family Takaful operates in a regulated environment that ensures compliance with both local laws and Shariah guidelines.",
    content4: "The plans are fully customizable to meet your individual needs, combining savings, investment, and protection to help you achieve your long-term financial goals while staying true to your beliefs. Choose Salaam Family Takaful for a comprehensive, Halal, and transparent solution that offers both protection and peace of mind from day one."
  },
]
export const News = [
  {
    key: "1",
    feature: `${process.env.REACT_APP_ASSETS_URL}Assets/img/Badri.jpg`,
    heading:"Salaam Family Takaful Limited and BADRI Management Consultancy commenced a strategic Partnership",
    content:"Salaam Family Takaful Limited, first ever digital family takaful, has entered a strategic partnership with BADRI Management Consultancy for the implementation of IFRS 17. As a newly licensed family takaful provider, the company has proactively adopted IFRS 17 from inception, ahead of regulatory requirements. This move demonstrates their commitment to transparency and best practices. With BADRI Management Consultancy’s ACE 17 accounting engine tailored for family takaful, this partnership aims to support the company's financial processes and drive innovation in the family takaful/insurance industry.",
    Date: "",
    archead: "",
    // excerpt:"CDC and Salaam Family Takaful sign an agreement to enhance transparency and efficiency ....."
  },
  {
    key: "2",
    feature: `${process.env.REACT_APP_ASSETS_URL}Assets/img/cdc.jpg`,
    heading:"CDC and Salaam Family Takaful sign an agreement to enhance transparency and efficiency in the insurance industry through centralized information sharing and repository systems",
    content:"Central Depository Company of Pakistan Limited and Salaam Family Takaful Limited have signed an agreement for the latter’s participation in the Centralized Insurance Repository and Information Sharing Solution (CISSII). This collaboration represents a major advancement in Pakistan's insurance and takaful sector, focusing on enhancing transparency, efficiency, and digitalization. Newly licensed by SECP, Salaam Family Takaful Limited is First Ever Digital-only Islamic life insurance provider, committed to supporting the growth and development of the insurance industry. By joining forces with the Central Depository Company of Pakistan Limited (CDC), Salaam Family Takaful Limited aims to leverage CDC’s state-of-the-art technological platform and extensive experience in similar projects.",
    content1:"The Centralized Information Sharing Solution for the Insurance Industry (CISSII) and Centralized Insurance Repository (CIR), developed and administered by CDC, are key cross-industry systems that facilitate the sharing of critical policy-related information among stakeholders. Launched in 2014 and 2020 respectively, these initiatives have laid the foundations for formal information-sharing mechanisms and have harmonized the industry’s data-sharing practices.",
    Date: "18-02-2022",
    archead: "",
    excerpt:"CDC and Salaam Family Takaful sign an agreement to enhance transparency and efficiency ....."
  },
  {
    key: "3",
    feature: `${process.env.REACT_APP_ASSETS_URL}Assets/img/secp.jpg`,
    heading:
      "Salaam Family Takaful Limited receives license from SECP to operate as the First Ever Digital Islamic Life Insurance Company",
    content:
      "Salaam Family Takaful Limited has officially arrived. In a recent ceremony which took place at SECP’s head quarters in Islamabad, Mr. Akif Saeed, Chairman of SECP, handed over the coveted license of the first ever Digital-Only Islamic Life Insurance Operator to Mr. Rizwan Hussain, the MD and CEO of the newly formed Salaam Family Takaful Limited. The new entity’s Shariah board was also present to witness this momentous happening which was well covered across the country’s leading media publications.",
    content1:
      "Salaam Family Takaful is set to redefine the industry with a cutting-edge end-to-end digital experience. Our new brand will be launched very soon which will epitomize customer centricity and innovation, poised to revolutionize the market with unparalleled policyholder benefits and real-time information access. This groundbreaking venture sets new benchmarks in the global Islamic Life Insurance segment.",
    content2:
      "Stay tuned for the unveiling of more groundbreaking and disruptive products that promise to transform the landscape of Islamic Life Insurance forever.",
    Date: "",
    archead: "",
    head: "A few glimpses of the coverage are as under:",

    excerpt:
      "Salaam Family Takaful Limited receives license from SECP to operate as the First Ever Digital Islamic Life",
    links: [
      "https://youtu.be/Dy13sVNdSfQ",
      "https://www.app.com.pk/.../salaam-family-takaful-limited.../",
      "https://www.brecorder.com/news/40304049",
      "https://epaper.dawn.com/DetailNews.php...",
      "https://www.arabnews.com/node/2511826/amp",
      "https://www.express.com.pk/epaper/PoPupwindow.aspx...",
      "https://startuppakistan.com.pk/salaam-family-takaful.../",
      "https://propakistani.pk/.../salaam-family-takaful.../",
    ],
  },
];
export const Forplanned = [
  {
    key: "1",
    heading: "To lodge a claim",
    text: "Show Health card for claim at Panel Hospital / Facility.File claim in case of utilizing non panel Facility / Hospital.",
    link: "",
  },

  {
    key: "2",
    heading: "Inform us",
    text: "Inform us 24-48 hours prior to your Admission date.",
    link: "",
  },

  {
    key: "3",
    heading: "Claim Form",
    text: "Download the claim form: click here",
    link: "https://www.salaamtakaful.com/Admin/assets/Content/462058e9-2a58_Claim%20Form.pdf",
  },

  {
    key: "4",
    heading: "Copy of necessary documents to be attached with the form",
    text: "Health card and CNIC need.Original Hospital itemized bill with stamp and all original cash receipts Copies of prescriptions, tests reports, birth/death certificates need to be attached where required.",
  },

  {
    key: "5",
    heading: "Acceptance of Claim",
    text: "Our Medical Team will evaluate your case. We’ll authorize it in line with your policy plan. You will avail the treatment and get discharged from the hospital.",
    link: "",
  },
];
export const ForEmergency = [
  {
    key: "1",
    text: "Need for medical treatment in hospital as advised by qualified doctor through OPD/emergency",
  },

  {
    key: "2",
    text: "Visit nearest network / panel hospital for the treatment.",
  },

  {
    key: "3",
    text: "Present Health card and CNIC (copies may be retained by hospital for billing)",
  },

  {
    key: "4",
    text: "Hospital will take approval from Salaam Family Takaful Health Card through email/phone, as per plan/ entitlement of Patient",
  },

  {
    key: "5",
    text: "Sign the bill preferably at the time of discharge and pay the difference if any (room or limit difference)",
  },
];
export const indvidualproducts = [
  {
    key: "1",
    name: "Salaam Motor Takaful",
    link: "/individualproduct/salammotor",
  },
  {
    key: "2",
    name: " Salaam Home Takaful",
    link: "/individualproduct/salamhome",
  },
  {
    key: "3",
    name: "Salaam Personal Accident Takaful",
    link: "/individualproduct/salampersonal",
  },
  {
    key: "4",
    name: "Salaam Sehat Takaful",
    link: "/individualproduct/salamsehat",
  },
  {
    key: "5",
    name: "Salaam Travel Takaful ",
    link: "/individualproduct/salamtravel",
  },
  {
    key: "6",
    name: "Salaam Livestock Takaful",
    link: "/individualproduct/salamlivestock",
  },
  {
    key: "7",
    name: "Salaam Crop Takaful",
    link: "/individualproduct/salamcrop",
  },
];
export const corporates = [
  {
    key: "1",
    name: "Salaam Miscellaneous Takaful",
    link: "/corporateproduct/salam-miscellaneous",
  },
  {
    key: "2",
    name: " Salaam Fire Takaful",
    link: "/corporateproduct/salam-fire",
  },
  {
    key: "3",
    name: "Salaam Engineering Takaful ",
    link: "/corporateproduct/salam-engineering",
  },
  {
    key: "4",
    name: "Salaam Marine Takaful",
    link: "/corporateproduct/salam-marine",
  },
  {
    key: "5",
    name: "Salaam Groleft Health Takaful",
    link: "/corporateproduct/salam-group-health",
  },
];
export const financials = [
  {
    key: "1",
    name: "Salaam Bankers Blanket Takaful",
    link: "/financialproduct/salaam-banker",
  },
  {
    key: "2",
    name: "Salaam Plastic Card Takaful",
    link: "/financialproduct/salaam-plasticcard",
  },
  {
    key: "3",
    name: "Salaam Safe Contribution Box Takaful Coverage ",
    link: "/financialproduct/salaam-deposit",
  },
  {
    key: "4",
    name: "Salaam Professional Indeminity Takaful",
    link: "/financialproduct/salaam-professional",
  },
  {
    key: "5",
    name: "Salaam Computer Crime Takaful",
    link: "/financialproduct/salaam-computer",
  },
  {
    key: "6",
    name: "Salaam Cyber Risk Takaful",
    link: "/financialproduct/salaam-cyber",
  },
];
export const SalaamDirectors = [
  {
    key: "1",
    name: "SALIM HABIB GODIL ",
    designation: "Chairman",
    detail:
      "Mr. Salim Habib Godil is a quintessential entrepreneur with business interests in automotive, construction and real estate, transportation and mobility, travel, and security solutions, including ownership of the country’s largest Toyota retail dealership. He also serves as Chairman of Pak Limousine Services which is the pioneer of the radio cab business in Pakistan as well as Chairman of Armored Vehicles Pakistan (formerly Striet Pakistan) which is the largest privately owned armored vehicle manufacturer in the country.Mr. Godil is the recipient of the Hilal-e-Imtiaz which is one of the highest civilian awards in Pakistan given in recognition of outstanding contribution to significant public endeavors. He was also given the FBR’s Highest Taxpayer Award 2019 which is awarded to he country`s top taxpayers identified by the Federal Board of Revenue. He is a certified Board Director .",
    animation: "vertical",
    caranim: "up",
    image: `${process.env.REACT_APP_ASSETS_URL}directors/salimhabib.png`,
  },

  {
    key: "2",
    name: "SYED RIZWAN HUSSAIN  ",
    designation: "Managing Director & CEO",
    detail:
      "Mr. Rizwan Hussain is a seasoned professional par excellence, possessing an immaculate experience of more than 35 years in a wide range of various leadership roles in local and foreign insurance enterprises, Mr. Rizwan’s name is well known in the corporate world. From he initial responsibilities and successes in product innovation and business network accumulation, he reached towering eights of accomplishments through his exquisite style of work and was soon given the reigns of multiple departments quite early in his career. He assumed the role of Managing Director and CEO of Salaam Family Takaful Limited in the year 2018 and since day one, has kept his focus to grow, adapt and innovate within the industry, to define the future of insurance / Takaful, deliver second-to-none experiences for customers, and help to build resilient communities with utmost transparency with zero compromise on corporate governance. His vision has always been to develop an organization which is the role model for the entire industry. ​He also serves as Non-Executive Director on the Board of Amana Takaful Insurance, Sri Lanka. He is a certified Board Director. ",
    animation: "vertical",
    caranim: "up",
    image: `${process.env.REACT_APP_ASSETS_URL}directors/rizwan.jpg`,
  },

  {
    key: "3",
    name: "SYED SALMAN HUSSAIN ",
    designation: "Non-Executive Director",
    detail:
      "Mr. Salman Hussain graduated from NED University, Karachi in mechanical engineering and holds a Master’s in Business from Cranfield University, United Kingdom. He is the Founder and CEO of iTecknologi Group of Companies which has emerged as one of the leading technology conglomerates in Pakistan. He brings a technology skillset to the Board. Mr. Salman has extensive management consulting experience with McKinsey Middle East and has provided consultations to various state-owned and private firms in the Middle East and across the region as well as International Finance orporation, and World Bank. He is a certified Board Direct.",
    animation: "vertical",
    caranim: "up",
    // image: `${process.env.REACT_APP_ASSETS_URL}directors/salman.jpg`,
  },

  {
    key: "4",
    name: "SHAHZAD SALIM GODIL",
    designation: "Non-Executive Director",
    detail:
      "Mr. Shahzad Salim Godil graduated from Clark University, Massachusetts in 2008 in business management, economics and entrepreneurship. He has been associated with a highly renowned conglomerate of Pakistan and serves in the capacity of Director, playing an instrumental role in the Group’s diversification into construction, transportation and mobility, and travel. He brings an entrepreneurial mindset to the Board. Shahzad Godil serves as Director in Toyota Central Motors, Toyota Society Motors, Toyota Shahra-e-Faisal, Pak Limousine Services, Armored Vehicles Pakistan (formerly Striet Pakistan), Metro Security Services (Pvt.) Ltd, Central Facilitation Agency (Pvt.) Ltd, Automall and Central Builders and Developers. He is a certified Board Director. ",
    animation: "vertical",
    caranim: "up",
    image: `${process.env.REACT_APP_ASSETS_URL}directors/shahzad.jpg`,
  },
  // {
  //   key: "5",
  //   name: "DR. IRUM SABA",
  //   designation: "Independent and non-Executive Director",
  //   detail:
  //     "Dr. Irum Saba has more than 17 years of progressive regulatory, research, training, and teaching experience with organizations of international repute. Currently, she is in Institute of Business Administration (IBA), Karachi working as an Associate Professor in the Department of Finance and Program Director of MS Islamic Banking and Finance. She has the honor to be the first Pakistani female to be part of the International Shariah Advisory Board in South East Asia and Middle East. Recently, she has been awarded the title of WOMENi Pakistan in recognition of her contribution to the islamic Finance Industry in Pakistan and internationally. Furthermore, Dr. Irum Saba has also been included in the prestigious list of top 10 Influential Women in Islamic Business & Finance 2021.  She is a Chartered Islamic Finance Professional (CIFP) with a PhD in Islamic Finance from INCEIF. She is also member of the Grand Council of the Chartered Institute of Islamic Finance Professionals (CIIF), Malaysia, and a Shari’ah-registered financial planner (Shari’ah RFP). She holds a Masters and PGD in Islamic banking and insurance from the Institute of Islamic Banking and Insurance (IIBI), London. Dr. Irum has authored several articles and book chapters on management, corporate governance, and Islamic finance and microfinance. She is a certified Board Director. ",
  //   animation: "vertical",
  //   caranim: "up",
    
  //   image: `${process.env.REACT_APP_ASSETS_URL}directors/irum.jpg`,
  // },
];
export const Salaamcomitiee = [
  {
    key: "1",
    name: "RIZWAN HUSSAIN ",
    designation: "CEO",
    detail:
      "Mr. Rizwan Hussain is a highly accomplished professional with over 30 years of experience in various leadership roles within the insurance industry, both locally and internationally. He is renowned in the corporate world for his exceptional work in product innovation and business network accumulation, which has led to his appointment as Managing Director and CEO of Salaam Family Takaful Limited in 2018. Since then, Mr. Rizwan has focused on growth, adaptation, and innovation within the industry to define the future of insurance and deliver top-notch experiences for customers while upholding transparency and corporate governance. His vision is to develop an organization that is a role model for the entire industry. Furthermore, he serves as a Non-Executive Director on the Board of Amana Takaful Insurance in Sri Lanka and is a certified Board Director.",
    animation: "vertical",
    caranim: "up",
    image: `${process.env.REACT_APP_ASSETS_URL}comitte/rizwan.png`,
  },

  // {
  //   key: "2",
  //   name: "Waqas Ahmad",
  //   designation: "Head of Family & Special Projects",
  //   detail: "Mr. Waqas Ahmad serves as the Executive Director for Family Takaful and Special Projects at Salaam Family Takaful Limited, showcasing an extraordinary career marked by over 30 years of distinguished leadership roles in both local and international insurance enterprises. He holds FLMI, an MBA and a PGD in Islamic Finance qualification. His areas of expertise encompass a wide spectrum, including Life and Health Operations, IT, Product Development, Retakaful, Strategic Planning, and Insuretech, among others. With a deep-seated commitment to the industry, Mr. Waqas has consistently explored innovative avenues to enhance public access to cutting-edge products, introduce structural reforms, and maintain operational excellence within the global Takaful landscape. His visionary leadership has empowered his team to pioneer product innovation, resulting in groundbreaking solutions both already developed and currently in progress. He played a pioneer role in building Salaam Family Takaful as Pakistan’s First Digital only Takaful venture from planning to execution.",
  //   animation: "vertical",
  //   caranim: "up",
  //   image: `${process.env.REACT_APP_ASSETS_URL}comitte/waqas.png`,
  // },

  {
    key: "2",
    name: "SYED NAYYAR HUSSAIN ",
    designation: "Head of Strategy & New Initiatives",
    detail:
      "With over 23+ years’ experience in insurance, Takaful, regulatory and private sector, Mr. Nayyar is working with us looking after strategy & new initiatives. He is mainly working on all the strategic initiatives of the company including strategy for family Takaful, risk management, product development and other regulatory affairs.",
    animation: "vertical",
    caranim: "up",
    image: `${process.env.REACT_APP_ASSETS_URL}comitte/nayyar.png`,
  },
  // {
  //   key: "4",
  //   name: "Sulaiman Lalani",
  //   designation: "Chief financial officer",
  //   detail: "Mr. Sulaiman Lalani possesses more than ten years of expertise in financial reporting, investments, and corporate compliance. He played a pivotal role in establishing the entire financial framework for the pioneering digital life Takaful company. He holds the distinguished status of being a Fellow member of the Chartered Certified Accountants (FCCA)",
  //   animation: "vertical",
  //   caranim: "up",
  //   // image: `../../assets/lalani.jpg`,
  //   image: `${process.env.REACT_APP_ASSETS_URL}directors/lalani.jpg`,
  // },

  {
    key: "3",
    name: "NOMAN ZAIDI",
    designation: "Head of Human Resource",
    detail:
      "Mr. Noman Zaidi is a highly experienced Human Resources specialist with a significant background in organizational development. He has accumulated 22 years of experience in this field, which is indicative of his expertise and knowledge in the realm of HR. Before joining Salaam Family Takaful Ltd, he served as the Director of Human Resources in a prominent organization.  In terms of his educational qualifications, Mr. Noman Zaidi holds a Master's degree in Human Resources from Kingston University in London. Furthermore, it's worth noting that he is a graduate member of the Chartered Institute of Personnel and Development (CIPD). Membership in a professional organization like CIPD suggests a commitment to ongoing professional development and adherence to industry standards and best practices in HR. Overall, Mr. Noman Zaidi's combination of extensive experience, academic qualifications, and professional memberships makes him a well-qualified HR specialist who can make valuable contributions to the field of human resources organizational development.",
    animation: "vertical",
    caranim: "up",
    // image: `../../assets/nomanzaidi.png`,
    image: `${process.env.REACT_APP_ASSETS_URL}directors/nomanzaidi.jpg`,
  },

  {
    key: "4",
    name: "FARHAN SHEIKH ",
    designation: "Head Of Marketing",
    detail:
      "Serving as Head of Marketing at Salaam Family Takaful Limited, Mr. Farhan Sheikh is an experienced marketing professional with a career spanning over 20 years covering a broad spectrum from Strategy Building, Planning, Budgeting, Marketing Communication with ATL, BTL, Digital and Guerilla Advertising tools. Additional expertise in brand building, sales channel formation, retention, and promotion. Moreover, he is also indulged in academia as visiting faculty in a couple of prestigious universities of Pakistan.",
    animation: "vertical",
    caranim: "up",
    image: `${process.env.REACT_APP_ASSETS_URL}comitte/farhan.jpg`,
  },

  {
    key: "5",
    name: "Muhammad Khurram",
    designation: "Head Of Information Technology",
    detail:
      "Mr. Khurram serves as the Head of IT function at Salaam Family Takaful Ltd. He has led the team and developed a Digital Life System with all applicable modules enabling Salaam Family Takaful to launch as the 1st Digital-Only company in Pakistan. He brings a dynamic blend of leadership, technical prowess, and extensive experience in the Life Insurance industry to our executive team. With a proven track record in leveraging technology to transform the insurance landscape, He is committed to steering the company toward innovation, digital transformation, and automation excellence. His journey in the Life Insurance sector spans 10-plus years of dedicated services, during which He has spearheaded projects on different platforms. His educational background includes a Master's degree in Software Project Management, and a Bachelor's degree in Software Engineering With extensive expertise in project management, Software Architecture, and technology infrastructure.",
    animation: "vertical",
    caranim: "up",
    image: `${process.env.REACT_APP_ASSETS_URL}comitte/khurram.jpg`,
  },

  {
    key: "6",
    name: "Muhammad Saleem",
    designation: "Head Of Operations",
    detail:
      "Muhammad Saleem is a highly experienced insurance professional with over 25 years of dedicated service in the fields of Takaful and insurance, both in Pakistan and the Middle East. Throughout his illustrious career, he has demonstrated expertise across various lines of business, including Individual, Group Life, Medical, and Bancatakaful.Mr. Saleem's impressive qualifications include being FLMI, ACII & M.Com (Insurance). His commitment to continuous learning and professional development underscores his dedication to the insurance industry.In his new role, he will be responsible for overseeing the Life Operations, where his wealth of knowledge and extensive experience will undoubtedly contribute to the continued success and growth of the organization.",
    animation: "vertical",
    caranim: "up",
    image: `${process.env.REACT_APP_ASSETS_URL}comitte/saleem.jpg`,
  },

  {
    key: "7",
    name: "Mr. Muhammad Imran Chowhan",
    designation: "Chief Financial Officer and Company Secretary",
    detail:
      "With over 20 years of distinguished experience in Finance, Accounts, Taxation, Audit, and Compliance, Mr. Muhammad Imran Chowhan stands out as a leading financial strategist and executive. As the Chief Financial Officer and Company Secretary at Salaam Family Takaful Ltd, he brings unparalleled expertise in steering financial strategy, optimizing costs, and mastering taxation intricacies. His remarkable career spans prestigious roles at Jubilee Life Insurance Company Limited-Pakistan, Afeef Packages Pvt Ltd-Pakistan, and DLW Accountant Pty Ltd-Australia. An esteemed ACCA member and Master of Commerce graduate, Mr. Chowhan is committed to driving financial innovation and operational excellence, ensuring the organization’s sustained growth and fiscal integrity.",
    animation: "vertical",
    caranim: "up",
    image: `${process.env.REACT_APP_ASSETS_URL}comitte/imran.png`,
  },
];
export const savingsprod = [
  {
    key: "1",
    mainheading: "SUPERCHARGE YOUR INVESTMENTS WITH 100% ALLOCATION",
    content:
      "This feature allows you to invest your funds entirely, without any deductions or fees. This means that the full amount you invest will go towards growing your investment portfolio, giving you the potential for higher returns and increased earnings. By maximizing your allocation, you can achieve your investment goals faster and more efficiently.",
    anim: "fade-up",
  },
  {
    key: "2",
    mainheading:
      "Bonus Bonanza! 10% Bonus on your contribution in year 10 and a 15% bonus in year 15 & 20.",
    content:
      "This feature provides  loyalty rewards for the participant for continuing with us with a 10% bonus on your contributions in year 10 and 15% bonus in year 15 and 20.",
    anim: "fade-up",
  },
  // {
  //   key: "3",
  //   mainheading:
  //     "CHOOSE FLEXIBLE CONTRIBUTION PAYMENTS - MONTHLY, QUARTERLY, OR YEARLY",
  //   content:
  //     "This feature allows you to choose the frequency of your contribution payments, giving you the flexibility to adjust your investments to suit your financial situation. You can opt to pay monthly, quarterly, or yearly, depending on your preference and budget. This allows you to manage your investments more effectively and take advantage of market opportunities.",
  //   anim: "fade-up",
  // },
  {
    key: "4",
    mainheading:
      "ENJOY UNLIMITED ACCESS TO YOUR FUNDS WITH NO WITHDRAWAL LIMITS OR FEES!",
    content:
      "This feature enables you to withdraw your funds at any time, with no restrictions or fees. This gives you the flexibility to manage your finances and access your funds whenever you need them, without incurring any charges. Having unrestricted access to your funds means you can react quickly to changing market conditions and make the most of investment opportunities.",
    anim: "fade-up",
  },
  {
    key: "5",
    mainheading: "ZERO BID-OFFER CHARGES ON INVESTMENTS!",
    content:
      "This feature eliminates the bid-offer spread that investors typically face when buying or selling investments. This means that you can buy and sell investments without incurring any additional costs, maximizing your returns and reducing your investment expenses. By eliminating this cost, you can potentially earn more from your investments and reduce the impact of fees on your returns.",
    anim: "fade-up",
  },
  {
    key: "6",
    mainheading: "NO WAKALA FEE ON MORTALITY CHARGE!",
    content:
      "This feature waives the Wakala fee on the mortality charge associated with your investments. The Wakala fee is typically charged to cover the administration costs associated with managing your investment. By waiving this fee, you can reduce your investment expenses and potentially earn more from your investments.",
    anim: "fade-up",
  },
  {
    key: "7",
    mainheading: "SIMPLIFIED CHARGE STRUCTURE",
    content:
      "This feature allows you to choose between two different investment plans based on your preferences and goals. The Inclusive plan provides both a death sum assured and cash value, while the Exclusive plan offers the higher of the sum assured or cash value. This gives you the flexibility to choose the plan that best suits your investment objectives and risk appetite.",
    anim: "fade-up",
  },
];

export const checkboxOptions1 = [
  "Morbid Obesity",
  "High Cholestrol",
  "Stroke ",
  "Tumor",
  "Multiple Sclerosis",
  "Nervous Disorder ",
  "Digestive System Disorder",
  "Psychiatric Illness",
  "Drug or Alcohol Abuse",
  " Hypertension",
  " Heart Disease",
  " Lungs Disease",
  "Cancer",
  "Organ Transplant",
  "Renal Failure",
  "Immune Disorder ",

  "Physical Impairment",
  "Physical Impairment",
  "Diabetes ",
  "Blood Disorder",
  " Liver Disease",
  "Thyroidism ",
  " HIV/AIDS",
  "Eye Disorders",
  "Hereditary",
  "  Joints Disorder",
];

export const checkboxOptions2 = [
  " Medical Consultation",
  " Any Abnormal Tests",
  " Any Long term Medication ",
  "Hopitilization",
  "COVID-19 (In history",
  "Surgery",
  "COVID-19 (Currently +ve)",
];
export const checkboxOptions3 = [
  " On average, how many units of alcohol do you consume per day",
  " If you smoke, how many cigarettes do you typically smoke per day",
  "What/How frequently do you use drugs or substances? ",
  "Others",
];

export const checkboxOptions4 = [
  " Illness",
  " Injury",
  "Disability Benefit",
  "Operation",
];
export const checkboxOptions5 = [
  "Complications occur during /after your pregnancy",
  " Any gynecological conditions or diseases",
  "Any specific treatments or interventions for breast diseases or conditions",
];

export const policyData = [
  {
    heading: "What is Personal Information ?",
    text: "Personal information is any information that can be used to identify you, such as your name, address, phone number, email address, or date of birth. We may also collect other information about you, such as your income, employment history, and medical history.",
    key: "0",
  },
  {
    heading: "How do we Collect Personal Information ?",
    text: "We collect personal information from you in a variety of ways, including:",
    key: "1",
    text1: [
      "When you visit our website, we may collect your IP address, browser type, and other information about your computer",
      "When you use our products or services, we may collect information about your use of those products or services, such as the products or services you have purchased, the claims you have made, and the payments you have made",
      "When you interact with us, we may collect information about your interaction, such as the questions you ask us and the feedback you provide",
    ],
  },
  {
    heading: "How do We User Personal Information ?",
    text: "We collect personal information from you in a variety of ways, including:",
    key: "2",
    text1: [
      "To provide you with the products and services you have requested",
      "To process your payments",
      "To communicate with you about your account or your Takaful policy",
      "To improve our products and services",
      "To comply with applicable laws and regulations",
      "To prevent fraud and abuse",
      "When you use our products or services, we may collect information about your use of those products or services, such as the products or services you have purchased, the claims you have made, and the payments you have made",
      "When you interact with us, we may collect information about your interaction, such as the questions you ask us and the feedback you provide",
    ],
  },
  {
    heading: "who We Share Personal Information With?",
    text: "We may share your personal information with the following third parties:",
    key: "3",
    text1: [
      "Our affiliates, including our parent company, subsidiaries, and other related entities",
      "Our service providers, who help us to provide our products and services, such as our claims processing company, our IT support provider, and our marketing agency",
      "Government agencies, if required by law",
    ],
  },
  {
    heading: "How do we protect personal Information ?",
    text: "We take steps to protect your personal information from unauthorized access, use, disclosure, alteration, or destruction. These steps include:",
    key: "4",
    text1: [
      "Using secure servers and encryption technology",
      "Requiring employees to sign confidentiality agreements",
      "Monitoring our security systems",
    ],
  },
  {
    heading: "Your Right",
    text: "You have certain rights with respect to your personal information, including the right to:",
    key: "5",
    text1: [
      "Access your personal information",
      "Request that we delete your personal information",
      "Object to the processing of your personal information",
      "Port your personal information to another company",
    ],
  },
  {
    heading: "Changes to this Privacy Policy",
    text: "We may update this Privacy Policy from time to time. Any changes will be reflected on this page.",
    key: "6",
  },
  {
    heading: "Contact us",
    text: "If you have any questions about this Privacy Policy, please contact us at marketing@salaamtakaful.com UAN: 021-111-875-111",
    key: "7",
  },
];

export const occupationOptions = [
  "accountant",
  "actor",
  "actuary",
  "adhesive bonding machine tender",
  "adjudicator",
  "administrative assistant",
  "administrative services manager",
  "adult education teacher",
  "advertising manager",
  "advertising sales agent",
  "aerobics instructor",
  "aerospace engineer",
  "aerospace engineering technician",
  "agent",
  "agricultural engineer",
  "agricultural equipment operator",
  "agricultural grader",
  "agricultural inspector",
  "agricultural manager",
  "agricultural sciences teacher",
  "agricultural sorter",
  "agricultural technician",
  "agricultural worker",
  "air conditioning installer",
  "air conditioning mechanic",
  "air traffic controller",
  "aircraft cargo handling supervisor",
  "aircraft mechanic",
  "aircraft service technician",
  "airline copilot",
  "airline pilot",
  "ambulance dispatcher",
  "ambulance driver",
  "amusement machine servicer",
  "anesthesiologist",
  "animal breeder",
  "animal control worker",
  "animal scientist",
  "animal trainer",
  "animator",
  "answering service operator",
  "anthropologist",
  "apparel patternmaker",
  "apparel worker",
  "arbitrator",
  "archeologist",
  "architect",
  "architectural drafter",
  "architectural manager",
  "archivist",
  "art director",
  "art teacher",
  "artist",
  "assembler",
  "astronomer",
  "athlete",
  "athletic trainer",
  "ATM machine repairer",
  "atmospheric scientist",
  "attendant",
  "audio and video equipment technician",
  "audio-visual and multimedia collections specialist",
  "audiologist",
  "auditor",
  "author",
  "auto damage insurance appraiser",
  "automotive and watercraft service attendant",
  "automotive glass installer",
  "automotive mechanic",
  "avionics technician",
  "back-end developer",
  "baggage porter",
  "bailiff",
  "baker",
  "barback",
  "barber",
  "bartender",
  "basic education teacher",
  "behavioral disorder counselor",
  "bellhop",
  "bench carpenter",
  "bicycle repairer",
  "bill and account collector",
  "billing and posting clerk",
  "biochemist",
  "biological technician",
  "biomedical engineer",
  "biophysicist",
  "blaster",
  "blending machine operator",
  "blockmason",
  "boiler operator",
  "boilermaker",
  "bookkeeper",
  "boring machine tool tender",
  "brazer",
  "brickmason",
  "bridge and lock tender",
  "broadcast news analyst",
  "broadcast technician",
  "brokerage clerk",
  "budget analyst",
  "building inspector",
  "bus mechanic",
  "butcher",
  "buyer",
  "cabinetmaker",
  "cafeteria attendant",
  "cafeteria cook",
  "camera operator",
  "camera repairer",
  "cardiovascular technician",
  "cargo agent",
  "carpenter",
  "carpet installer",
  "cartographer",
  "cashier",
  "caster",
  "ceiling tile installer",
  "cellular equipment installer",
  "cement mason",
  "channeling machine operator",
  "chauffeur",
  "checker",
  "chef",
  "chemical engineer",
  "chemical plant operator",
  "chemist",
  "chemistry teacher",
  "chief executive",
  "child social worker",
  "childcare worker",
  "chiropractor",
  "choreographer",
  "civil drafter",
  "civil engineer",
  "civil engineering technician",
  "claims adjuster",
  "claims examiner",
  "claims investigator",
  "cleaner",
  "clinical laboratory technician",
  "clinical laboratory technologist",
  "clinical psychologist",
  "coating worker",
  "coatroom attendant",
  "coil finisher",
  "coil taper",
  "coil winder",
  "coin machine servicer",
  "commercial diver",
  "commercial pilot",
  "commodities sales agent",
  "communications equipment operator",
  "communications teacher",
  "community association manager",
  "community service manager",
  "compensation and benefits manager",
  "compliance officer",
  "composer",
  "computer hardware engineer",
  "computer network architect",
  "computer operator",
  "computer programmer",
  "computer science teacher",
  "computer support specialist",
  "computer systems administrator",
  "computer systems analyst",
  "concierge",
  "conciliator",
  "concrete finisher",
  "conservation science teacher",
  "conservation scientist",
  "conservation worker",
  "conservator",
  "construction inspector",
  "construction manager",
  "construction painter",
  "construction worker",
  "continuous mining machine operator",
  "convention planner",
  "conveyor operator",
  "cook",
  "cooling equipment operator",
  "copy marker",
  "correctional officer",
  "correctional treatment specialist",
  "correspondence clerk",
  "correspondent",
  "cosmetologist",
  "cost estimator",
  "costume attendant",
  "counseling psychologist",
  "counselor",
  "courier",
  "court reporter",
  "craft artist",
  "crane operator",
  "credit analyst",
  "credit checker",
  "credit counselor",
  "criminal investigator",
  "criminal justice teacher",
  "crossing guard",
  "curator",
  "custom sewer",
  "customer service representative",
  "cutter",
  "cutting machine operator",
  "dancer",
  "data entry keyer",
  "database administrator",
  "decorating worker",
  "delivery services driver",
  "demonstrator",
  "dental assistant",
  "dental hygienist",
  "dental laboratory technician",
  "dentist",
  "dermatologist",
  "derrick operator",
  "designer",
  "desktop publisher",
  "detective",
  "developer",
  "diagnostic medical sonographer",
  "die maker",
  "diesel engine specialist",
  "dietetic technician",
  "dietitian",
  "dinkey operator",
  "director",
  "dishwasher",
  "dispatcher",
  "DJ",
  "doctor",
  "door-to-door sales worker",
  "drafter",
  "dragline operator",
  "drama teacher",
  "dredge operator",
  "dressing room attendant",
  "dressmaker",
  "drier operator",
  "drilling machine tool operator",
  "dry-cleaning worker",
  "drywall installer",
  "dyeing machine operator",
  "earth driller",
  "economics teacher",
  "economist",
  "editor",
  "education administrator",
  "electric motor repairer",
  "electrical electronics drafter",
  "electrical engineer",
  "electrical equipment assembler",
  "electrical installer",
  "electrical power-line installer",
  "electrician",
  "electro-mechanical technician",
  "elementary school teacher",
  "elevator installer",
  "elevator repairer",
  "embalmer",
  "emergency management director",
  "emergency medical technician",
  "engine assembler",
  "engineer",
  "engineering manager",
  "engineering teacher",
  "english language teacher",
  "engraver",
  "entertainment attendant",
  "environmental engineer",
  "environmental science teacher",
  "environmental scientist",
  "epidemiologist",
  "escort",
  "etcher",
  "event planner",
  "excavating operator",
  "executive administrative assistant",
  "executive secretary",
  "exhibit designer",
  "expediting clerk",
  "explosives worker",
  "extraction worker",
  "fabric mender",
  "fabric patternmaker",
  "fabricator",
  "faller",
  "family practitioner",
  "family social worker",
  "family therapist",
  "farm advisor",
  "farm equipment mechanic",
  "farm labor contractor",
  "farmer",
  "farmworker",
  "fashion designer",
  "fast food cook",
  "fence erector",
  "fiberglass fabricator",
  "fiberglass laminator",
  "file clerk",
  "filling machine operator",
  "film and video editor",
  "financial analyst",
  "financial examiner",
  "financial manager",
  "financial services sales agent",
  "fine artist",
  "fire alarm system installer",
  "fire dispatcher",
  "fire inspector",
  "fire investigator",
  "firefighter",
  "fish and game warden",
  "fish cutter",
  "fish trimmer",
  "fisher",
  "fitness studies teacher",
  "fitness trainer",
  "flight attendant",
  "floor finisher",
  "floor layer",
  "floor sander",
  "floral designer",
  "food batchmaker",
  "food cooking machine operator",
  "food preparation worker",
  "food science technician",
  "food scientist",
  "food server",
  "food service manager",
  "food technologist",
  "foreign language teacher",
  "foreign literature teacher",
  "forensic science technician",
  "forest fire inspector",
  "forest fire prevention specialist",
  "forest worker",
  "forester",
  "forestry teacher",
  "forging machine setter",
  "foundry coremaker",
  "freight agent",
  "freight mover",
  "front-end developer",
  "fundraising manager",
  "funeral attendant",
  "funeral director",
  "funeral service manager",
  "furnace operator",
  "furnishings worker",
  "furniture finisher",
  "gaming booth cashier",
  "gaming cage worker",
  "gaming change person",
  "gaming dealer",
  "gaming investigator",
  "gaming manager",
  "gaming surveillance officer",
  "garment mender",
  "garment presser",
  "gas compressor",
  "gas plant operator",
  "gas pumping station operator",
  "general manager",
  "general practitioner",
  "geographer",
  "geography teacher",
  "geological engineer",
  "geological technician",
  "geoscientist",
  "glazier",
  "government program eligibility interviewer",
  "graduate teaching assistant",
  "graphic designer",
  "groundskeeper",
  "groundskeeping worker",
  "gynecologist",
  "hairdresser",
  "hairstylist",
  "hand grinding worker",
  "hand laborer",
  "hand packager",
  "hand packer",
  "hand polishing worker",
  "hand sewer",
  "hazardous materials removal worker",
  "head cook",
  "health and safety engineer",
  "health educator",
  "health information technician",
  "health services manager",
  "health specialties teacher",
  "healthcare social worker",
  "hearing officer",
  "heat treating equipment setter",
  "heating installer",
  "heating mechanic",
  "heavy truck driver",
  "highway maintenance worker",
  "historian",
  "history teacher",
  "hoist and winch operator",
  "home appliance repairer",
  "home economics teacher",
  "home entertainment installer",
  "home health aide",
  "home management advisor",
  "host",
  "hostess",
  "hostler",
  "hotel desk clerk",
  "housekeeping cleaner",
  "human resources assistant",
  "human resources manager",
  "human service assistant",
  "hunter",
  "hydrologist",
  "illustrator",
  "industrial designer",
  "industrial engineer",
  "industrial engineering technician",
  "industrial machinery mechanic",
  "industrial production manager",
  "industrial truck operator",
  "industrial-organizational psychologist",
  "information clerk",
  "information research scientist",
  "information security analyst",
  "information systems manager",
  "inspector",
  "instructional coordinator",
  "instructor",
  "insulation worker",
  "insurance claims clerk",
  "insurance sales agent",
  "insurance underwriter",
  "intercity bus driver",
  "interior designer",
  "internist",
  "interpreter",
  "interviewer",
  "investigator",
  "jailer",
  "janitor",
  "jeweler",
  "judge",
  "judicial law clerk",
  "kettle operator",
  "kiln operator",
  "kindergarten teacher",
  "laboratory animal caretaker",
  "landscape architect",
  "landscaping worker",
  "lathe setter",
  "laundry worker",
  "law enforcement teacher",
  "law teacher",
  "lawyer",
  "layout worker",
  "leather worker",
  "legal assistant",
  "legal secretary",
  "legislator",
  "librarian",
  "library assistant",
  "library science teacher",
  "library technician",
  "licensed practical nurse",
  "licensed vocational nurse",
  "life scientist",
  "lifeguard",
  "light truck driver",
  "line installer",
  "literacy teacher",
  "literature teacher",
  "loading machine operator",
  "loan clerk",
  "loan interviewer",
  "loan officer",
  "lobby attendant",
  "locker room attendant",
  "locksmith",
  "locomotive engineer",
  "locomotive firer",
  "lodging manager",
  "log grader",
  "logging equipment operator",
  "logistician",
  "machine feeder",
  "machinist",
  "magistrate judge",
  "magistrate",
  "maid",
  "mail clerk",
  "mail machine operator",
  "mail superintendent",
  "maintenance painter",
  "maintenance worker",
  "makeup artist",
  "management analyst",
  "manicurist",
  "manufactured building installer",
  "mapping technician",
  "marble setter",
  "marine engineer",
  "marine oiler",
  "market research analyst",
  "marketing manager",
  "marketing specialist",
  "marriage therapist",
  "massage therapist",
  "material mover",
  "materials engineer",
  "materials scientist",
  "mathematical science teacher",
  "mathematical technician",
  "mathematician",
  "maxillofacial surgeon",
  "measurer",
  "meat cutter",
  "meat packer",
  "meat trimmer",
  "mechanical door repairer",
  "mechanical drafter",
  "mechanical engineer",
  "mechanical engineering technician",
  "mediator",
  "medical appliance technician",
  "medical assistant",
  "medical equipment preparer",
  "medical equipment repairer",
  "medical laboratory technician",
  "medical laboratory technologist",
  "medical records technician",
  "medical scientist",
  "medical secretary",
  "medical services manager",
  "medical transcriptionist",
  "meeting planner",
  "mental health counselor",
  "mental health social worker",
  "merchandise displayer",
  "messenger",
  "metal caster",
  "metal patternmaker",
  "metal pickling operator",
  "metal pourer",
  "metal worker",
  "metal-refining furnace operator",
  "metal-refining furnace tender",
  "meter reader",
  "microbiologist",
  "middle school teacher",
  "milling machine setter",
  "millwright",
  "mine cutting machine operator",
  "mine shuttle car operator",
  "mining engineer",
  "mining safety engineer",
  "mining safety inspector",
  "mining service unit operator",
  "mixing machine setter",
  "mobile heavy equipment mechanic",
  "mobile home installer",
  "model maker",
  "model",
  "molder",
  "mortician",
  "motel desk clerk",
  "motion picture projectionist",
  "motorboat mechanic",
  "motorboat operator",
  "motorboat service technician",
  "motorcycle mechanic",
  "movers",
  "multimedia artist",
  "museum technician",
  "music director",
  "music teacher",
  "musical instrument repairer",
  "musician",
  "natural sciences manager",
  "naval architect",
  "network systems administrator",
  "new accounts clerk",
  "news vendor",
  "nonfarm animal caretaker",
  "nuclear engineer",
  "nuclear medicine technologist",
  "nuclear power reactor operator",
  "nuclear technician",
  "nursing aide",
  "nursing instructor",
  "nursing teacher",
  "nutritionist",
  "obstetrician",
  "occupational health and safety specialist",
  "occupational health and safety technician",
  "occupational therapist",
  "occupational therapy aide",
  "occupational therapy assistant",
  "offbearer",
  "office clerk",
  "office machine operator",
  "operating engineer",
  "operations manager",
  "operations research analyst",
  "ophthalmic laboratory technician",
  "optician",
  "optometrist",
  "oral surgeon",
  "order clerk",
  "order filler",
  "orderly",
  "ordnance handling expert",
  "orthodontist",
  "orthotist",
  "outdoor power equipment mechanic",
  "oven operator",
  "packaging machine operator",
  "painter ",
  "painting worker",
  "paper goods machine setter",
  "paperhanger",
  "paralegal",
  "paramedic",
  "parking enforcement worker",
  "parking lot attendant",
  "parts salesperson",
  "paving equipment operator",
  "payroll clerk",
  "pediatrician",
  "pedicurist",
  "personal care aide",
  "personal chef",
  "personal financial advisor",
  "personal trainer",
  "pest control worker",
  "pesticide applicator",
  "pesticide handler",
  "pesticide sprayer",
  "petroleum engineer",
  "petroleum gauger",
  "petroleum pump system operator",
  "petroleum refinery operator",
  "petroleum technician",
  "pharmacist",
  "pharmacy aide",
  "pharmacy technician",
  "philosophy teacher",
  "photogrammetrist",
  "photographer",
  "photographic process worker",
  "photographic processing machine operator",
  "physical therapist aide",
  "physical therapist assistant",
  "physical therapist",
  "physician assistant",
  "physician",
  "physicist",
  "physics teacher",
  "pile-driver operator",
  "pipefitter",
  "pipelayer",
  "planing machine operator",
  "planning clerk",
  "plant operator",
  "plant scientist",
  "plasterer",
  "plastic patternmaker",
  "plastic worker",
  "plumber",
  "podiatrist",
  "police dispatcher",
  "police officer",
  "policy processing clerk",
  "political science teacher",
  "political scientist",
  "postal service clerk",
  "postal service mail carrier",
  "postal service mail processing machine operator",
  "postal service mail processor",
  "postal service mail sorter",
  "postmaster",
  "postsecondary teacher",
  "poultry cutter",
  "poultry trimmer",
  "power dispatcher",
  "power distributor",
  "power plant operator",
  "power tool repairer",
  "precious stone worker",
  "precision instrument repairer",
  "prepress technician",
  "preschool teacher",
  "priest",
  "print binding worker",
  "printing press operator",
  "private detective",
  "probation officer",
  "procurement clerk",
  "producer",
  "product promoter",
  "product manager",
  "production clerk",
  "production occupation",
  "proofreader",
  "property manager",
  "prosthetist",
  "prosthodontist",
  "psychiatric aide",
  "psychiatric technician",
  "psychiatrist",
  "psychologist",
  "psychology teacher",
  "public relations manager",
  "public relations specialist",
  "pump operator",
  "purchasing agent",
  "purchasing manager",
  "radiation therapist",
  "radio announcer",
  "radio equipment installer",
  "radio operator",
  "radiologic technician",
  "radiologic technologist",
  "rail car repairer",
  "rail transportation worker",
  "rail yard engineer",
  "rail-track laying equipment operator",
  "railroad brake operator",
  "railroad conductor",
  "railroad police",
  "rancher",
  "real estate appraiser",
  "real estate broker",
  "real estate manager",
  "real estate sales agent",
  "receiving clerk",
  "receptionist",
  "record clerk",
  "recreation teacher",
  "recreation worker",
  "recreational therapist",
  "recreational vehicle service technician",
  "recyclable material collector",
  "referee",
  "refractory materials repairer",
  "refrigeration installer",
  "refrigeration mechanic",
  "refuse collector",
  "regional planner",
  "registered nurse",
  "rehabilitation counselor",
  "reinforcing iron worker",
  "reinforcing rebar worker",
  "religion teacher",
  "religious activities director",
  "religious worker",
  "rental clerk",
  "repair worker",
  "reporter",
  "residential advisor",
  "resort desk clerk",
  "respiratory therapist",
  "respiratory therapy technician",
  "retail buyer",
  "retail salesperson",
  "revenue agent",
  "rigger",
  "rock splitter",
  "rolling machine tender",
  "roof bolter",
  "roofer",
  "rotary drill operator",
  "roustabout",
  "safe repairer",
  "sailor",
  "sales engineer",
  "sales manager",
  "sales representative",
  "sampler",
  "sawing machine operator",
  "scaler",
  "school bus driver",
  "school psychologist",
  "school social worker",
  "scout leader",
  "sculptor",
  "secondary education teacher",
  "secondary school teacher",
  "secretary",
  "securities sales agent",
  "security guard",
  "security system installer",
  "segmental paver",
  "self-enrichment education teacher",
  "semiconductor processor",
  "septic tank servicer",
  "set designer",
  "sewer pipe cleaner",
  "sewing machine operator",
  "shampooer",
  "shaper",
  "sheet metal worker",
  "sheriff's patrol officer",
  "ship captain",
  "ship engineer",
  "ship loader",
  "shipmate",
  "shipping clerk",
  "shoe machine operator",
  "shoe worker",
  "short order cook",
  "signal operator",
  "signal repairer",
  "singer",
  "ski patrol",
  "skincare specialist",
  "slaughterer",
  "slicing machine tender",
  "slot supervisor",
  "social science research assistant",
  "social sciences teacher",
  "social scientist",
  "social service assistant",
  "social service manager",
  "social work teacher",
  "social worker",
  "sociologist",
  "sociology teacher",
  "software developer",
  "software engineer",
  "soil scientist",
  "solderer",
  "sorter",
  "sound engineering technician",
  "space scientist",
  "special education teacher",
  "speech-language pathologist",
  "sports book runner",
  "sports entertainer",
  "sports performer",
  "stationary engineer",
  "statistical assistant",
  "statistician",
  "steamfitter",
  "stock clerk",
  "stock mover",
  "stonemason",
  "street vendor",
  "streetcar operator",
  "structural iron worker",
  "structural metal fabricator",
  "structural metal fitter",
  "structural steel worker",
  "stucco mason",
  "substance abuse counselor",
  "substance abuse social worker",
  "subway operator",
  "surfacing equipment operator",
  "surgeon",
  "surgical technologist",
  "survey researcher",
  "surveying technician",
  "surveyor",
  "switch operator",
  "switchboard operator",
  "tailor",
  "tamping equipment operator",
  "tank car loader",
  "taper",
  "tax collector",
  "tax examiner",
  "tax preparer",
  "taxi driver",
  "teacher assistant",
  "teacher",
  "team assembler",
  "technical writer",
  "telecommunications equipment installer",
  "telemarketer",
  "telephone operator",
  "television announcer",
  "teller",
  "terrazzo finisher",
  "terrazzo worker",
  "tester",
  "textile bleaching operator",
  "textile cutting machine setter",
  "textile knitting machine setter",
  "textile presser",
  "textile worker",
  "therapist",
  "ticket agent",
  "ticket taker",
  "tile setter",
  "timekeeping clerk",
  "timing device assembler",
  "tire builder",
  "tire changer",
  "tire repairer",
  "title abstractor",
  "title examiner",
  "title searcher",
  "tobacco roasting machine operator",
  "tool filer",
  "tool grinder",
  "tool maker",
  "tool sharpener",
  "tour guide",
  "tower equipment installer",
  "tower operator",
  "track switch repairer",
  "tractor operator",
  "tractor-trailer truck driver",
  "traffic clerk",
  "traffic technician",
  "training and development manager",
  "training and development specialist",
  "transit police",
  "translator",
  "transportation equipment painter",
  "transportation inspector",
  "transportation security screener",
  "transportation worker",
  "trapper",
  "travel agent",
  "travel clerk",
  "travel guide",
  "tree pruner",
  "tree trimmer",
  "trimmer",
  "truck loader",
  "truck mechanic",
  "tuner",
  "turning machine tool operator",
  "tutor",
  "typist",
  "umpire",
  "undertaker",
  "upholsterer",
  "urban planner",
  "usher",
  "UX designer",
  "valve installer",
  "vending machine servicer",
  "veterinarian",
  "veterinary assistant",
  "veterinary technician",
  "vocational counselor",
  "vocational education teacher",
  "waiter",
  "waitress",
  "watch repairer",
  "water treatment plant operator",
  "weaving machine setter",
  "web developer",
  "weigher",
  "welder",
  "wellhead pumper",
  "wholesale buyer",
  "wildlife biologist",
  "window trimmer",
  "wood patternmaker",
  "woodworker",
  "word processor",
  "writer",
  "yardmaster",
  "zoologist",
];

export const suplementryData = [
  // {
  //   key: 1,
  //   image: "../assets/Artboard.svg",
  //   heading: "Permanent and Total Disablement Takaful Benefit (PTD)",
  //   text: "Receive financial assistance in case of permanent and total disability",
  // },

  // {
  //   key: 2,
  //   image: "../assets/Family.svg",
  //   heading: "Family Income Takaful Benefit (FITB)",
  //   text: "Get a steady income for your family in case of your death",
  // },
  // {
  //   key: 3,
  //   image: "../assets/Waiver.svg",
  //   heading: "Waiver of Contribution Takaful Benefit (WODTB)",
  //   text: "Continue to earn profits without making further contributions in case of total and permanent disability",
  // },
  // {
  //   key: 4,
  //   image: "../assets/Accidental.svg",
  //   heading: "Accidental Death and Dismemberment Takaful Benefit (ADDTB)",
  //   text: "Receive financial assistance in case of accidental death or loss of limbs or eyesight.",
  // },

  // {
  //   key: 5,
  //   image: "../assets/Critical.svg",
  //   heading: "Critical Illness Takaful Benefit (CITB)",
  //   text: "Receive financial assistance in case of critical illnesses such as cancer, heart attack, or stroke.",
  // },
  {
    key: 1,
    image: "../assets/Accidental2.svg",
    heading: "Accidental Death Takaful Benefit (ADTB)",
    text: "Receive financial assistance in case of accidental death",
  },
];

export const buyNowImageData = [
  {
    index: 0,
    image: `${process.env.REACT_APP_ASSETS_URL}Assets/buynow/step1.jpg`,
  },
  {
    index: 1,
    // image: "../assets/step2.jpg",
    image: `${process.env.REACT_APP_ASSETS_URL}Assets/buynow/step2.jpg`,
  },
  {
    index: 2,
    // image: "../assets/step3.jpg",
    image: `${process.env.REACT_APP_ASSETS_URL}Assets/buynow/step3.jpg`,
  },
  {
    index: 3,
    // image: "../assets/step4.jpg",
    image: `${process.env.REACT_APP_ASSETS_URL}Assets/buynow/step4.jpg`,
  },
  {
    index: 4,
    // image: "../assets/step5.jpg",
    image: `${process.env.REACT_APP_ASSETS_URL}Assets/buynow/step5.jpg`,
  },
  {
    index: 5,
    // image: "../assets/step6.png",
    image: `${process.env.REACT_APP_ASSETS_URL}Assets/buynow/step6.png`,
  },
  {
    index: 6,
    // image: "../assets/step7.jpg",
    image: `${process.env.REACT_APP_ASSETS_URL}Assets/buynow/step7.jpg`,
  },
  {
    index: 7,
    // image: "../assets/step8.jpg",
    image: `${process.env.REACT_APP_ASSETS_URL}Assets/buynow/step8.jpg`,
  },
  {
    index: 8,
    // image: "../assets/step9.png",
    image: `${process.env.REACT_APP_ASSETS_URL}Assets/buynow/step9.png`,
  },
  {
    index: 9,
    // image: "../assets/step8.jpg",
    image: `${process.env.REACT_APP_ASSETS_URL}Assets/buynow/step8.jpg`,
  },
  {
    index: 10,
    // image: "../assets/step11.png",
    image: `${process.env.REACT_APP_ASSETS_URL}Assets/buynow/step11.png`,
  },
  {
    index: 11,
    // image: "../assets/step12.jpg",
    image: `${process.env.REACT_APP_ASSETS_URL}Assets/buynow/step12.jpg`,
  },
];

export const videos = [
  {
    key: 1,
    link: "https://asset.salaamlifetakaful.com/SavingAssets/Assets/video/khalidmaliksalesteam.mp4",
    title: "Salaam Life & Savings Product Details",
  },

  {
    key: 2,
    link: "https://asset.salaamlifetakaful.com/SavingAssets/Assets/video/lifeusp.mp4",
    title: "Salaam Life & Savings USPs",
  },

  // {
  //   key: 1,
  //   link: "https://asset.salaamlifetakaful.com/SavingAssets/Assets/video/onboarding.mp4",
  //   title: "Salaam Life & Savings Customer Onboarding Journey",
  // },

  {
    key: 1,
    link: "https://asset.salaamlifetakaful.com/SavingAssets/Assets/video/aijaz.mp4",
    title: "Dr. Mufti Irshad Ahmad Aijaz",
  },

  {
    key: 1,
    link: "https://asset.salaamlifetakaful.com/SavingAssets/Assets/video/sajad.mp4",
    title: "Mufti Sajjad Ashraf Usmani",
  },

  {
    key: 1,
    link: "https://asset.salaamlifetakaful.com/SavingAssets/Assets/video/shabir.mp4",
    title: "Ayatullah Sheikh Shabbir Hasan Lakhani (Maisami)",
  },

  {
    key: 1,
    link: "https://asset.salaamlifetakaful.com/SavingAssets/Assets/video/younus.mp4",
    title: "Dr. Mufti Muhammad Younas Ali",
  },
];

export const groupprods = [
  {
    key: "1",
    mainheading: "Ethical and Shariah-compliant",
    content:
      "Nurturing a culture of ethical and moral values in everything we do, aligning with Islamic principles.",
    anim: "fade-up",
  },
  {
    key: "2",
    mainheading: "Mutual assistance and cooperation",
    content:
      "Building a sense of unity and solidarity, fostering a supportive community through shared responsibility and mutual aid.",
    anim: "fade-up",
  },
  {
    key: "3",
    mainheading: "Customized Takaful solutions",
    content:
      "Craing unique and customized protection plans, perfectly suited to each participant's needs.",
    anim: "fade-up",
  },
  {
    key: "4",
    mainheading: "Exceptional customer service",
    content:
      "Providing a superior level of service, ensuring customer satisfaction and deligh.",
    anim: "fade-up",
  },
  {
    key: "5",
    mainheading: "Innovative technology",
    content:
      "Pushing the boundaries of innovation, delivering cutting-edge solutions that lead the way.",
    anim: "fade-up",
  },
  {
    key: "6",
    mainheading: "State-of-the-art Salaam Application",
    content:
      "A digital ecosystem that is a true masterpiece of technology, offering a plethora of features and ease of use.",
    anim: "fade-up",
  },
];

export const suplementryDataGroup = [
  {
    key: 1,
    image: "../assets/Artboard.svg",
    heading: "Permanent and Total Disablement Takaful Benefit (PTD)",
    text: "Receive financial assistance in case of permanent and total disability",
  },
  {
    key: 2,
    image: "../assets/Total.png",
    heading: "TOTAL ADAPTABILITY",
    text: " This allows new eligible employees to join or existing employees to leave the group during the policy term.",
  },
  {
    key: 3,
    image: "../assets/WorldWide.png",
    heading: "WORLDWIDE COVERAGE",
    text: "Our Takaful product provides coverage for eligible individual worldwide, ensuring protection and peace of mind no matter where life takes them.",
  },
  {
    key: 4,
    image: "../assets/Administration.png",
    heading: "ADMINISTRATION PORTAL",
    text: "Our Takaful product features a convenient and user-friendly administration portal, allowing employers to manage their coverage and access  important information easily and efficiently.",
  },
  {
    key: 5,
    image: "../assets/Unlimited.png",
    heading: "UNLIMITED EMPLOYEE COVERAGE",
    text: "    Our Takaful product ensures that all eligible individual are protected by allowing an unlimited number of employees to be covered under the policy.  ",
  },
  {
    key: 7,
    image: "../assets/Comp.png",
    heading: "COMPREHENSIVE DEATH BENEFIT COVERAGE",
    text: "Our Takaful product offers comprehensive death benefit coverage to eligible individual, providing financial security and support to loved ones in the event of unexpected loss.",
  },
  {
    key: 8,
    image: "../assets/Surplus.png",
    heading: "SURPLUS SHARING FROM TAKAFUL POOL",
    text: "Our Takaful product allows eligible organization to benefit from surplus sharing from the Takaful pool, offering the potential for additional  financial gains.",
  },
  {
    key: 9,
    image: "../assets/World.png",
    heading: "TAILORED TAKAFUL COVERAGE",
    text: "Employers can link the level of cover to their eligible employees' earnings by offering a multiple of salary, or they can set a specific sum assured for each employee grade or group.",
  },
];

export const eligibilityData = [
  {
    key: 1,
    // image: "../assets/Artboard.svg",
    number: 10,
    heading: "Employees",
    text: "Minimum Active Workforce",
  },
  {
    key: 2,
    number: 18,
    heading: "Years",
    text: " Minimum Age At Entry",
  },
  {
    key: 3,
    number: 64,
    // image: "../assets/Artboard.svg",
    heading: "Years",
    text: "Maximum Age at Entry",
  },
  {
    key: 4,
    number: 65,
    // image: "../assets/Artboard.svg",
    heading: "Years",
    text: "Maximum Age at Expiry",
  },
];
export const Jobs = [
  {
    key: "1",
    title: "Actuarial",
    Detail: [
      {
        key: "1",
        heading1: "Sales Manager",
        detailjob: [
          {
            key: "1",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "2",
        heading1: "Sales BOD",
        detailjob: [
          {
            key: "2",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "3",
        heading1: "Call Sales",
        detailjob: [
          {
            key: "3",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "4",
        heading1: "Sales Director",
        detailjob: [
          {
            key: "4",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "5",
        heading1: "Sales Bidder",
        detailjob: [
          {
            key: "5",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "6",
        heading1: "Sales Financer",
        detailjob: [
          {
            key: "6",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "7",
        heading1: "Sales Finance Manager",
        detailjob: [
          {
            key: "7",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      // Add more items as needed
    ],
  },

  {
    key: "2",
    title: "Administration",
    Detail: [
      {
        key: "1",
        heading1: "Finance Manager",
        detailjob: [
          {
            key: "1",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "2",
        heading1: "Sales BOD",
        detailjob: [
          {
            key: "2",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "3",
        heading1: "Call Sales",
        detailjob: [
          {
            key: "3",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "4",
        heading1: "Sales Director",
        detailjob: [
          {
            key: "4",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "5",
        heading1: "Sales Bidder",
        detailjob: [
          {
            key: "5",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "6",
        heading1: "Sales Financer",
        detailjob: [
          {
            key: "6",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "7",
        heading1: "Sales Finance Manager",
        detailjob: [
          {
            key: "7",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      // Add more items as needed
    ],
  },

  {
    key: "3",
    title: "Agency Management",
    Detail: [
      {
        key: "1",
        heading1: "Admin Manager",
        detailjob: [
          {
            key: "1",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "2",
        heading1: "Sales BOD",
        detailjob: [
          {
            key: "2",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "3",
        heading1: "Call Sales",
        detailjob: [
          {
            key: "3",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "4",
        heading1: "Sales Director",
        detailjob: [
          {
            key: "4",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "5",
        heading1: "Sales Bidder",
        detailjob: [
          {
            key: "5",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "6",
        heading1: "Sales Financer",
        detailjob: [
          {
            key: "6",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "7",
        heading1: "Sales Finance Manager",
        detailjob: [
          {
            key: "7",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      // Add more items as needed
    ],
  },

  {
    key: "4",
    title: "Business Development",
    Detail: [
      {
        key: "1",
        heading1: "HR Manager",
        detailjob: [
          {
            key: "1",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "2",
        heading1: "Sales BOD",
        detailjob: [
          {
            key: "2",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "3",
        heading1: "Call Sales",
        detailjob: [
          {
            key: "3",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "4",
        heading1: "Sales Director",
        detailjob: [
          {
            key: "4",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "5",
        heading1: "Sales Bidder",
        detailjob: [
          {
            key: "5",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "6",
        heading1: "Sales Financer",
        detailjob: [
          {
            key: "6",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "7",
        heading1: "Sales Finance Manager",
        detailjob: [
          {
            key: "7",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      // Add more items as needed
    ],
  },

  {
    key: "5",
    title: "Claims",
    Detail: [
      {
        key: "1",
        heading1: "Paid Intenship",
        detailjob: [
          {
            key: "1",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "2",
        heading1: "Sales BOD",
        detailjob: [
          {
            key: "2",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "3",
        heading1: "Call Sales",
        detailjob: [
          {
            key: "3",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "4",
        heading1: "Sales Director",
        detailjob: [
          {
            key: "4",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "5",
        heading1: "Sales Bidder",
        detailjob: [
          {
            key: "5",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "6",
        heading1: "Sales Financer",
        detailjob: [
          {
            key: "6",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "7",
        heading1: "Sales Finance Manager",
        detailjob: [
          {
            key: "7",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Burglary and theft",
            res4: "Fire & external explosion",
            res5: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res6: "Riot and strike damage,",
            res7: "Any natural incident (Earthquake, Flood, etc.)",
            res8: "Risk of terrorism.",
            res9: "Third Party",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      // Add more items as needed
    ],
  },
  {
    key: "6",
    title: "Commercial",
    Detail: [
      {
        key: "1",
        heading1: "Paid Intenship",
        detailjob: [
          {
            key: "1",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "2",
        heading1: "Sales BOD",
        detailjob: [
          {
            key: "2",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "3",
        heading1: "Call Sales",
        detailjob: [
          {
            key: "3",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "4",
        heading1: "Sales Director",
        detailjob: [
          {
            key: "4",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "5",
        heading1: "Sales Bidder",
        detailjob: [
          {
            key: "5",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "6",
        heading1: "Sales Financer",
        detailjob: [
          {
            key: "6",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "7",
        heading1: "Sales Finance Manager",
        detailjob: [
          {
            key: "7",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Burglary and theft",
            res4: "Fire & external explosion",
            res5: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res6: "Riot and strike damage,",
            res7: "Any natural incident (Earthquake, Flood, etc.)",
            res8: "Risk of terrorism.",
            res9: "Third Party",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      // Add more items as needed
    ],
  },
  {
    key: "7",
    title: "Corporate Relationship",
    Detail: [
      {
        key: "1",
        heading1: "Paid Intenship",
        detailjob: [
          {
            key: "1",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "2",
        heading1: "Sales BOD",
        detailjob: [
          {
            key: "2",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "3",
        heading1: "Call Sales",
        detailjob: [
          {
            key: "3",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "4",
        heading1: "Sales Director",
        detailjob: [
          {
            key: "4",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "5",
        heading1: "Sales Bidder",
        detailjob: [
          {
            key: "5",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "6",
        heading1: "Sales Financer",
        detailjob: [
          {
            key: "6",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "7",
        heading1: "Sales Finance Manager",
        detailjob: [
          {
            key: "7",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Burglary and theft",
            res4: "Fire & external explosion",
            res5: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res6: "Riot and strike damage,",
            res7: "Any natural incident (Earthquake, Flood, etc.)",
            res8: "Risk of terrorism.",
            res9: "Third Party",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      // Add more items as needed
    ],
  },
  {
    key: "8",
    title: "Customer Service",
    Detail: [
      {
        key: "1",
        heading1: "Paid Intenship",
        detailjob: [
          {
            key: "1",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "2",
        heading1: "Sales BOD",
        detailjob: [
          {
            key: "2",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "3",
        heading1: "Call Sales",
        detailjob: [
          {
            key: "3",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "4",
        heading1: "Sales Director",
        detailjob: [
          {
            key: "4",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "5",
        heading1: "Sales Bidder",
        detailjob: [
          {
            key: "5",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "6",
        heading1: "Sales Financer",
        detailjob: [
          {
            key: "6",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "7",
        heading1: "Sales Finance Manager",
        detailjob: [
          {
            key: "7",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Burglary and theft",
            res4: "Fire & external explosion",
            res5: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res6: "Riot and strike damage,",
            res7: "Any natural incident (Earthquake, Flood, etc.)",
            res8: "Risk of terrorism.",
            res9: "Third Party",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      // Add more items as needed
    ],
  },
  {
    key: "9",
    title: "Emerging Lines",
    Detail: [
      {
        key: "1",
        heading1: "Paid Intenship",
        detailjob: [
          {
            key: "1",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "2",
        heading1: "Sales BOD",
        detailjob: [
          {
            key: "2",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "3",
        heading1: "Call Sales",
        detailjob: [
          {
            key: "3",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "4",
        heading1: "Sales Director",
        detailjob: [
          {
            key: "4",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "5",
        heading1: "Sales Bidder",
        detailjob: [
          {
            key: "5",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "6",
        heading1: "Sales Financer",
        detailjob: [
          {
            key: "6",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "7",
        heading1: "Sales Finance Manager",
        detailjob: [
          {
            key: "7",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Burglary and theft",
            res4: "Fire & external explosion",
            res5: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res6: "Riot and strike damage,",
            res7: "Any natural incident (Earthquake, Flood, etc.)",
            res8: "Risk of terrorism.",
            res9: "Third Party",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      // Add more items as needed
    ],
  },
  {
    key: "10",
    title: "Finance & Accounts",
    Detail: [
      {
        key: "1",
        heading1: "Paid Intenship",
        detailjob: [
          {
            key: "1",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "2",
        heading1: "Sales BOD",
        detailjob: [
          {
            key: "2",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "3",
        heading1: "Call Sales",
        detailjob: [
          {
            key: "3",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "4",
        heading1: "Sales Director",
        detailjob: [
          {
            key: "4",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "5",
        heading1: "Sales Bidder",
        detailjob: [
          {
            key: "5",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "6",
        heading1: "Sales Financer",
        detailjob: [
          {
            key: "6",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "7",
        heading1: "Sales Finance Manager",
        detailjob: [
          {
            key: "7",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Burglary and theft",
            res4: "Fire & external explosion",
            res5: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res6: "Riot and strike damage,",
            res7: "Any natural incident (Earthquake, Flood, etc.)",
            res8: "Risk of terrorism.",
            res9: "Third Party",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      // Add more items as needed
    ],
  },
  {
    key: "11",
    title: "Health",
    Detail: [
      {
        key: "1",
        heading1: "Paid Intenship",
        detailjob: [
          {
            key: "1",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "2",
        heading1: "Sales BOD",
        detailjob: [
          {
            key: "2",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "3",
        heading1: "Call Sales",
        detailjob: [
          {
            key: "3",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "4",
        heading1: "Sales Director",
        detailjob: [
          {
            key: "4",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "5",
        heading1: "Sales Bidder",
        detailjob: [
          {
            key: "5",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "6",
        heading1: "Sales Financer",
        detailjob: [
          {
            key: "6",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "7",
        heading1: "Sales Finance Manager",
        detailjob: [
          {
            key: "7",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Burglary and theft",
            res4: "Fire & external explosion",
            res5: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res6: "Riot and strike damage,",
            res7: "Any natural incident (Earthquake, Flood, etc.)",
            res8: "Risk of terrorism.",
            res9: "Third Party",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      // Add more items as needed
    ],
  },
  {
    key: "12",
    title: "Human Resource",
    Detail: [
      {
        key: "1",
        heading1: "Paid Intenship",
        detailjob: [
          {
            key: "1",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "2",
        heading1: "Sales BOD",
        detailjob: [
          {
            key: "2",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "3",
        heading1: "Call Sales",
        detailjob: [
          {
            key: "3",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "4",
        heading1: "Sales Director",
        detailjob: [
          {
            key: "4",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "5",
        heading1: "Sales Bidder",
        detailjob: [
          {
            key: "5",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "6",
        heading1: "Sales Financer",
        detailjob: [
          {
            key: "6",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "7",
        heading1: "Sales Finance Manager",
        detailjob: [
          {
            key: "7",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Burglary and theft",
            res4: "Fire & external explosion",
            res5: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res6: "Riot and strike damage,",
            res7: "Any natural incident (Earthquake, Flood, etc.)",
            res8: "Risk of terrorism.",
            res9: "Third Party",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      // Add more items as needed
    ],
  },
  {
    key: "13",
    title: "Information Technology",
    Detail: [
      {
        key: "1",
        heading1: "Paid Intenship",
        detailjob: [
          {
            key: "1",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "2",
        heading1: "Sales BOD",
        detailjob: [
          {
            key: "2",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "3",
        heading1: "Call Sales",
        detailjob: [
          {
            key: "3",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "4",
        heading1: "Sales Director",
        detailjob: [
          {
            key: "4",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "5",
        heading1: "Sales Bidder",
        detailjob: [
          {
            key: "5",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "6",
        heading1: "Sales Financer",
        detailjob: [
          {
            key: "6",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "7",
        heading1: "Sales Finance Manager",
        detailjob: [
          {
            key: "7",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Burglary and theft",
            res4: "Fire & external explosion",
            res5: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res6: "Riot and strike damage,",
            res7: "Any natural incident (Earthquake, Flood, etc.)",
            res8: "Risk of terrorism.",
            res9: "Third Party",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      // Add more items as needed
    ],
  },
  {
    key: "14",
    title: "Legal & Compliance",
    Detail: [
      {
        key: "1",
        heading1: "Paid Intenship",
        detailjob: [
          {
            key: "1",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "2",
        heading1: "Sales BOD",
        detailjob: [
          {
            key: "2",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "3",
        heading1: "Call Sales",
        detailjob: [
          {
            key: "3",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "4",
        heading1: "Sales Director",
        detailjob: [
          {
            key: "4",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "5",
        heading1: "Sales Bidder",
        detailjob: [
          {
            key: "5",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "6",
        heading1: "Sales Financer",
        detailjob: [
          {
            key: "6",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "7",
        heading1: "Sales Finance Manager",
        detailjob: [
          {
            key: "7",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Burglary and theft",
            res4: "Fire & external explosion",
            res5: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res6: "Riot and strike damage,",
            res7: "Any natural incident (Earthquake, Flood, etc.)",
            res8: "Risk of terrorism.",
            res9: "Third Party",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      // Add more items as needed
    ],
  },
  {
    key: "15",
    title: "Marketing",
    Detail: [
      {
        key: "1",
        heading1: "Paid Intenship",
        detailjob: [
          {
            key: "1",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "2",
        heading1: "Sales BOD",
        detailjob: [
          {
            key: "2",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "3",
        heading1: "Call Sales",
        detailjob: [
          {
            key: "3",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "4",
        heading1: "Sales Director",
        detailjob: [
          {
            key: "4",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "5",
        heading1: "Sales Bidder",
        detailjob: [
          {
            key: "5",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "6",
        heading1: "Sales Financer",
        detailjob: [
          {
            key: "6",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "7",
        heading1: "Sales Finance Manager",
        detailjob: [
          {
            key: "7",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Burglary and theft",
            res4: "Fire & external explosion",
            res5: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res6: "Riot and strike damage,",
            res7: "Any natural incident (Earthquake, Flood, etc.)",
            res8: "Risk of terrorism.",
            res9: "Third Party",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      // Add more items as needed
    ],
  },
  {
    key: "16",
    title: "Sales",
    Detail: [
      {
        key: "1",
        heading1: "Paid Intenship",
        detailjob: [
          {
            key: "1",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "2",
        heading1: "Sales BOD",
        detailjob: [
          {
            key: "2",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "3",
        heading1: "Call Sales",
        detailjob: [
          {
            key: "3",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "4",
        heading1: "Sales Director",
        detailjob: [
          {
            key: "4",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "5",
        heading1: "Sales Bidder",
        detailjob: [
          {
            key: "5",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "6",
        heading1: "Sales Financer",
        detailjob: [
          {
            key: "6",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "7",
        heading1: "Sales Finance Manager",
        detailjob: [
          {
            key: "7",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Burglary and theft",
            res4: "Fire & external explosion",
            res5: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res6: "Riot and strike damage,",
            res7: "Any natural incident (Earthquake, Flood, etc.)",
            res8: "Risk of terrorism.",
            res9: "Third Party",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      // Add more items as needed
    ],
  },
  {
    key: "17",
    title: "Service Quality & Excellence",
    Detail: [
      {
        key: "1",
        heading1: "Paid Intenship",
        detailjob: [
          {
            key: "1",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "2",
        heading1: "Sales BOD",
        detailjob: [
          {
            key: "2",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "3",
        heading1: "Call Sales",
        detailjob: [
          {
            key: "3",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",

            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "4",
        heading1: "Sales Director",
        detailjob: [
          {
            key: "4",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "5",
        heading1: "Sales Bidder",
        detailjob: [
          {
            key: "5",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "6",
        heading1: "Sales Financer",
        detailjob: [
          {
            key: "6",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res4: "Riot and strike damage,",
            res5: "Any natural incident (Earthquake, Flood, etc.)",
            res6: "Risk of terrorism.",
            res7: "Third Party",
            res8: "",
            res9: "",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      {
        key: "7",
        heading1: "Sales Finance Manager",
        detailjob: [
          {
            key: "7",
            textinfo:
              "Salaam Family Takaful Limited offers Private Car Third party, a reliable solution to protect you against an accident to Third Party which meets legal motor insurance/Takaful requirements under Motor Vehicle Act 1939.                            ",
            res1: "Burglary and theft",
            res2: "Fire & external explosion",
            res3: "Burglary and theft",
            res4: "Fire & external explosion",
            res5: "Risk while in transit by air, road, rail, inland waterway, lift or elevetor.",
            res6: "Riot and strike damage,",
            res7: "Any natural incident (Earthquake, Flood, etc.)",
            res8: "Risk of terrorism.",
            res9: "Third Party",
            res10: "",
            resp1: "Personal Accident Cover (Optional)",
            resp2: "",
            resp3: "",
            resp4: "",
            resp5: "",
            resp6: "",
            resp7: "",
            resp8: "",
            resp9: "",
            resp10: "",
            edu1: "Personal Accident Cover (Optional)",
            edu2: "",
            edu3: "",
            edu4: "",
            edu5: "",
          },
        ],
      },

      // Add more items as needed
    ],
  },
  {
    key: "18",
    title: "Underwriting",
    
  },
];

export const Intern = [
  {
    key: "1",
    title: "Organizational Development",
    Detail: [
      {
        id: "1",
        image:
          "https://www.salaamtakaful.com/assets/images/Management/TraineeProgram.jpg",
        heading1: "Shariah General Training:",
        text1:
          "Various trainings are conducted throughout the years to inculcate adequate skills to ensure professional growth for the employees. Being a Shariah Compliant company, it is essential to understand the principles and aspects of Takaful, hence Shariah training is an important aspect of the company. The training is devised by our very own Shariah advisor, Mufti Sajjad Ashraf Usmani, which focus on the aspect and importance of having policy and procedure in accordance with the line of shariah, the operation process of how Takaful operates and its difference from conventional Insurance.",
        heading2: "Development Programs:",
        text2:
          "The purpose of the development programs is to allow our valued employees to acquire skills (such as nonverbal ques and positive thinking) which would enhance productivity and help employees in their personal and professional growth.",
        li1: "",
        li2: "",
        li3: "",
        li4: "",
        li5: "",
        li6: "",
        text3: "",
      },
    ],
  },

  {
    key: "2",
    title: "Health & Recreational Facility",
    Detail: [
      {
        id: "2",
        image:
          "https://www.salaamtakaful.com/assets/images/Management/Healthrecreational.png",
        heading1: "Health & Recreational Facility",
        text1:
          "Salaam Family Takaful Limited believes that healthy and energetic work force collaborates better, improves company’s productivity and generates employee satisfaction. To contribute to positive environment, keeping employee health paramount. Salaam Family Takaful Limited has taken multiple initiative such as:",
        heading2: "",
        text2: "",
        li1: "Initiation of “Gym” facility for all employees",
        li2: "Ramadan Iftaar",
        li3: "Yearly Picnic",
        li4: "Calories & BMI check-up at office premises",
        li5: "Adequate measure such as use of sanitizers & mask during throughout the facility",
        li6: "Availability of health professionals for assistance",
        text3: "",
      },
    ],
  },

  {
    key: "3",
    title: "CSR",
    Detail: [
      {
        id: "3",
        image: "https://www.salaamtakaful.com/assets/images/Management/CSR.png",
        heading1: " “Salaamti Humesha k lie” ",
        text1:
          "Salaam Family Takaful Limited, in line with its mission & Slogan has always believed in contributing to the benefit of the community and its environment. Our strategies to work towards the sustainable living can be witnessed in our initiatives as mentioned below:",
        heading2: "",
        text2: "",
        li1: "Medical Eye Camp, providing Free Eye Check to over 100 participants.",
        li2: "Blood Donation Camp",
        li3: "",
        li4: "",
        li5: "",
        li6: "",
        text3: "",
      },
    ],
  },

  {
    key: "4",
    title: "Cricket Match",
    Detail: [
      {
        id: "4",
        image:
          "https://www.salaamtakaful.com/assets/images/Management/CricketMatch.png",
        heading1: " ",
        text1:
          "Salaam Family Takaful Limited enthusiastically participates in various cricket tournaments throughout the year. Out of the multiple tournaments Salaam Family Takaful Limited cricket team, with the efforts of its teammate and leadership of their captain, have achieved the following results:",
        heading2: "",
        text2: "",
        li1: "Winner IAP (Insurance Association of Pakistan) Tape Ball Cricket Tournament, 2019",
        li2: "Winner Dubai Islamic Bank, Hard Ball Cricket Tournament – 2019",
        li3: "Semi-Finalist IAP (Insurance Association of Pakistan) Tape Ball Cricket Tournament, 2018",
        li4: "Participant in Insurance Premier League, Hard Ball Cricket Tournament, 2019",
        li5: "",
        li6: "",
        text3:
          "These tournaments not only infuse unity amongst the employees but also focus on team building",
      },
    ],
  },

  {
    key: "5",
    title: "Trainee Program",
    Detail: [
      {
        id: "5",
        image:
          "https://www.salaamtakaful.com/assets/images/Management/TraineeProgram.jpg",
        heading1: " Trainee Program",
        text1:
          "Salaam Family Takaful Limited strongly believes in the principle Equality and Diversity. We are always on the lookout for energetic and dynamic individual and hence have launched two programs:",
        heading2: "",
        text2: "",
        li1: "Management Trainee Program",
        li2: "Sales Trainee Program",
        li3: "",
        li4: "",
        li5: "",
        li6: "",
        text3:
          "To combine the energy of individual with the expertise of skilled management personnel to shape the future leaders of tomorrow.",
      },
    ],
  },
];
export const Esgtabss = [
  {
    key: "1",
    mainheading: "Environment",
    content:
      "On the environmental side we constantly push for a greener future, both internally & externally. Our initiatives in green financing remain relevant today as well as in the upcoming future, as we continue to minimize consumption. Salaam Family Takaful is also a pioneer in bringing digitization within the Takaful industry, so much so, that we have made our organizational processes completely paperless, with an objective of digitizing everything to the hilt.",
    image:
      `${process.env.REACT_APP_ASSETS_URL}Assets/Sustainability/Environment.jpg`,
  },

  {
    key: "2",
    mainheading: "Social",
    content:
      "If we talk about the social aspect in ESG, Salaam Family Takaful has again a lot of feathers in the cap. Takaful itself is based on the idea of social protection which is a structure of mutual cooperation and solidarity for the purpose of protecting the weak and vulnerable in society. This reflects that our very existence is for the upliftment of the society in general. Besides, arrangement of health and wellness camps, introduction of parametric based crops for farmers resilience, launch of MTO programme for Madaris graduates and impacting thousands of lives through our health programs are few of the other notable contributions of Salaam Family Takaful Limited.",
    image: `${process.env.REACT_APP_ASSETS_URL}Assets/Sustainability/Social.jpg`,
  },

  {
    key: "3",
    mainheading: "Governance",
    content:
      "Governance is not something new for us. Our founders understood transparency as a key foundation and embedded those principles in our culture. For this we have spared no cost on our commitments to governance & transparency. In fact, we have tried to take those extra steps and set an example for those around us.",
    image:
      `${process.env.REACT_APP_ASSETS_URL}Assets/Sustainability/Governance.jpg`,
  },

  {
    key: "4",
    mainheading: "Environment",
    content:
      "On the environmental side we constantly push for a greener future, both internally & externally. Our initiatives in green financing remain relevant today as well as in the upcoming future, as we continue to minimize consumption. Salaam Family Takaful is also a pioneer in bringing digitization within the Takaful industry, so much so, that we have made our organizational processes completely paperless, with an objective of digitizing everything to the hilt.",
    image:
      `${process.env.REACT_APP_ASSETS_URL}Assets/Sustainability/Environment.jpg`,
  },

  {
    key: "5",
    mainheading: "Social",
    content:
      "If we talk about the social aspect in ESG, Salaam Family Takaful has again a lot of feathers in the cap. Takaful itself is based on the idea of social protection which is a structure of mutual cooperation and solidarity for the purpose of protecting the weak and vulnerable in society. This reflects that our very existence is for the upliftment of the society in general. Besides, arrangement of health and wellness camps, introduction of parametric based crops for farmers resilience, launch of MTO programme for Madaris graduates and impacting thousands of lives through our health programs are few of the other notable contributions of Salaam Family Takaful Limited.",
    image: `${process.env.REACT_APP_ASSETS_URL}Assets/Sustainability/Social.jpg`,
  },

  {
    key: "6",
    mainheading: "Governance",
    content:
      "Governance is not something new for us. Our founders understood transparency as a key foundation and embedded those principles in our culture. For this we have spared no cost on our commitments to governance & transparency. In fact, we have tried to take those extra steps and set an example for those around us.",
    image:
      `${process.env.REACT_APP_ASSETS_URL}Assets/Sustainability/Governance.jpg`,
  },

  {
    key: "7",
    mainheading: "Environment",
    content:
      "On the environmental side we constantly push for a greener future, both internally & externally. Our initiatives in green financing remain relevant today as well as in the upcoming future, as we continue to minimize consumption. Salaam Family Takaful is also a pioneer in bringing digitization within the Takaful industry, so much so, that we have made our organizational processes completely paperless, with an objective of digitizing everything to the hilt.",
    image:
      `${process.env.REACT_APP_ASSETS_URL}Assets/Sustainability/Environment.jpg`,
  },

  {
    key: "8",
    mainheading: "Social",
    content:
      "If we talk about the social aspect in ESG, Salaam Family Takaful has again a lot of feathers in the cap. Takaful itself is based on the idea of social protection which is a structure of mutual cooperation and solidarity for the purpose of protecting the weak and vulnerable in society. This reflects that our very existence is for the upliftment of the society in general. Besides, arrangement of health and wellness camps, introduction of parametric based crops for farmers resilience, launch of MTO programme for Madaris graduates and impacting thousands of lives through our health programs are few of the other notable contributions of Salaam Family Takaful Limited.",
    image: `${process.env.REACT_APP_ASSETS_URL}Assets/Sustainability/Social.jpg`,
  },

  {
    key: "9",
    mainheading: "Governance",
    content:
      "Governance is not something new for us. Our founders understood transparency as a key foundation and embedded those principles in our culture. For this we have spared no cost on our commitments to governance & transparency. In fact, we have tried to take those extra steps and set an example for those around us.",
    image:
      `${process.env.REACT_APP_ASSETS_URL}Assets/Sustainability/Governance.jpg`,
  },

];

export const companyDetailsData = [
  {
    id: 1,
    heading: "Company Incorporation No.",
    text: "189454",
  },
  // {
  //   id: 2,
  //   heading: "Company Registration No. and Date",
  //   text: "Permitted through registration No. 189, dated 9th March, 2007.",
  // },

  {
    id: 3,
    heading: "Company Incorporation Date.",
    text: "2nd December 2021",
  },
  {
    id: 4,
    heading: "Status of the Company.",
    text: "Small-Sized Company(SSC)",
  },
  {
    id: 5,
    heading: "National Tax Number (NTN)",
    text: "4690336",
  },
  {
    id: 6,
    heading: "Detail of Permissible Business Activities",
    text: "Life Takaful (Islamic Insurance)",
  },
  {
    id: 7,
    heading: "Certificate of Incorporation",
    text: `<a href="${process.env.REACT_APP_ASSETS_URL}Assets/pdf/SFTL.pdf" target="_blank">Download</a>`,
  },
  // {
  //   id: 8,
  //   heading: "Date of Commencement of Business.",
  //   text: "12 march, 2007",
  // },
  {
    id: 9,
    heading: "Name of Statutory Auditor",
    text: `KPMG Taseer Hadi & Co. Chartered Accountants, Sheikh Sultan Trust Building No.2 Beaumont Road, Shahrah-e-Faisal, Karachi - 75530, Pakistan Phones: (0092-21) 3568 5847 Fax: (0092-21) 3568 5095 Web: <a href="https://www.home.kpmg.com" target="_blank">https://www.home.kpmg.com</a>`,
  },
  {
    id: 10,
    heading: "Regulator of the Company",
    text: `Securities & Exchange Commission of Pakistan Insurance Division, State Life Building-2, 4Th Floor, Wallance Road, Karachi, Pakistan Web link: <a href="http://www.secp.gov.pk" target="_blank">http://www.secp.gov.pk</a>`,
  },
  {
    id: 11,
    heading: "Legal Advisor",
    text: `AHM & Co Address: 415, Eden Heights, Jail Road, Lahore | Email: info@ahmco.pk | Phone: 042-35786749 | Website: <a href="http://ahmco.pk/" target="_blank">http://ahmco.pk/</a>`,
  },
  // {
  //   id: 12,
  //   heading: "Corporate Advisor",
  //   text: "RS Corporate Advisory: First Floor, Plot No.62, Central Commercial Area (CCA), Block-T, Phase-2 DHA, Lahore Cantt.",
  // },

  // {
  //   id: 13,
  //   heading: "Membership",
  //   text: "Insurance Association of Pakistan (IAP) & Karachi Chamber of Commerce and Industries (KCCI)",
  // },

  // {
  //   id: 14,
  //   heading: "Share Registrar",
  //   text: "Central Depository Company of Pakistan Limited,CDC House.99-B,Block-B, S.M.C.H.S,Main Shahra-e-Faisal. Karachi.|Tel UAN:(+92 21) 111 111 500| Email: info@cdcpak.com",
  // },
  {
    id: 16,
    heading: "Appointed Actuary",
    text: "Akhtar & Hasan (Private) Limited Actuaries (A&H) is an independent Actuarial Consultancy, set up in 1975, by Messrs Samee-ul-Hasan and S. S. Akhtar.  A&H has been servicing the financial industry for more than 50 years, both locally and internationally. The firm enjoys the trust and respect of its clients which is only possible with the utmost professional standards and uncompromising service consistently throughout.",
  },
  {
    id: 15,
    heading: "Retakaful",
    text: "Salaam Family Takaful Limited has opted the services of  Hanover ReTakaful. Hannover ReTakaful B.S.C. (c) (HRe) is a fully owned subsidiary of Hannover Re Group, responsible for the Group’s Retakaful business worldwide. HRe Bahrain office also consists of the Hannover Re Bahrain Branch, which covers conventional reinsurance business in selected markets, namely Saudi Arabia and Pakistan. HRe is an innovative, flexible, and leading Retakaful provider having an independent rating of A+ from S&P, distinguishing us from our competitors. Salaam Family Takaful has got separate Retakaful arrangements with HRe for Individual Family Takaful & Group Family Takaful contracts. Business is ceded up to agreed limits under all Retakaful agreements and are transacted on a risk premium basis.",
  },
];

export const companyDetailAccordionData = [
  {
    heading: "STATEMENT OF ETHICS:",
    subHeading: "The Directors and Employees of the Company shall:",
    text1: [
      "Act with integrity, competence and dignity when dealing with the public, participants, prospects, employers, directors and peers.",
      "Practice and encourage others to practice in a professional and ethical manner.",
      "Strive to maintain and improve their competence and the competence of others in the profession.",
      "Use reasonable care and exercise independent professional judgement.",
      "Refrain from divulging Company’s sensitive information to outsiders.",
    ],
    key: "0",
  },
];
export const companyDetailAccordionData1 = [
  {
    heading: "STATEMENT OF BUSINESS PRACTICES:",
    subHeading: "The Directors and Employees of the Company shall:",
    textData: [
      {
        heading: "Salaam Family Takaful Limited's Directors and employees shall:",
        text1: [
          "Act with integrity, competence and dignity when dealing with the public, participants, prospects, employers, directors and peers.",
          "Practice and encourage others to practice in a professional and ethical manner.",
          "Strive to maintain and improve their competence and the competence of others in the profession.",
          "Use reasonable care and exercise independent professional judgement.",
          "Refrain from divulging Company’s sensitive information to outsiders.",
          "Encouraging or permitting persons associated with them to offer, promise or pay bribes or receive bribes. Bribes include payments of cash, gifts, entertainment or anything of value, or an inducement of any kind.",
          "Take any cash or near-to-cash benefit from any stake holder of the Company to provide undue advantage.",

          "Pay any cash or near-to-cash benefit to any stake holder of the Company to take undue advantage.",
          "ABC i.e. making or receiving of bribes or corrupt payments in any form and will uphold all laws relevant to countering bribery and corruption in all the jurisdictions in which we operate.",
          "Directors and employees may offer or accept only such gifts, entertainment and hospitality that are inconsequential.",
        ],
      },
      {
        heading:
          "Relationship with and Responsibilities to Clients and Prospects:",
        text1: [
          "Exercise diligence and thoroughness in the performance of their duties, recommendations or in taking actions.",
          "Have a reasonable and adequate basis, supported by appropriate research and investigation, for such recommendations or actions.",
          "Make reasonable or diligent efforts to avoid any material misrepresentation.",
          "Maintain appropriate records to support the reasonableness of such recommendation or action.",
          "Use reasonable care and judgment to achieve and maintain independence and objectivity in the performance of their job responsibilities.",
          "Must act for the benefit of the participants and place participants’ interests before their own.",
          "Preserve the confidentiality of information communicated by their clients, prospect or employers concerning matters within the scope of client-employee or employer-employee relationships unless the employee receives information concerning illegal activities on the part of the client, prospect or employer.",
          "Not make any statement, orally or in writing, that misrepresent:",
          "The services that they or the Company is capable of performing",
          "Their qualifications or the qualifications of the Company",
          "The academic or professional credentials",
          "Any assurance or guarantees regarding any Takaful coverage except to communicate accurate information regarding the terms of the policy instrument and the issuer’s obligations under the said Takaful policy",
          "Not make any statement, orally or in writing, that misrepresent the performance that they or their Company has accomplished or can reasonably be expected to achieve.",
          "Not communicate individual or Company performance information directly or indirectly to the participants, or prospective participants or in a manner intended to be received by participants or prospective participants and shall make reasonable effort to ensure that such information is fair, accurate and a complete presentation of such performance.",
        ],
      },
      {
        heading: "Fundamental responsibilities:",
        text1: [
          "The directors & employees shall maintain knowledge of and comply with all the applicable laws, rules and regulations governing the Company;",
          "Not knowingly participate or assist in any violation of such laws, rules or regulations",
        ],
      },
      {
        heading: "Relationship with and Responsibilities to the Company.",
        text1: [
          "Not undertake any independent practice that could result in compensation or other benefit in competition with their Company unless they obtain written consent from both Company and the person or entities from which they undertake independent practice.",
          "Disclose to the Company all matters, including beneficial ownership of securities or other investments that could reasonably be expected to interfere with their duty to the Company or ability to make unbiased and objective recommendations.",
          "Comply with prohibitions or activities imposed by the Company if a conflict of interest exists.",
          "Exercise reasonable supervision over those subject to their supervision or authority to prevent any violation of applicable statutes or regulations.",
          "Ensure proper flow of adequate information.",
        ],
      },
    ],
    key: "1",
  },
];

export const shariahData = [


  {
      key: "1",
      name: ' MUFTI SAJJAD ASHRAF USMANI',
      designation: 'Shariah Advisor',
      detail: 'Mufti Sajjad Usmani is a qualified CSAA (Certified Shariah Advisor & Auditor) from AAOIFI (Accounting and Auditing Organization for Islamic Financial Institutions, Bahrain), Takhassus Fil Ifta (Specialization in Islamic Jurisprudence and Fatwa) and Dars-e-Nizami/Shahadat-ul-Aalamia from Jamia Dar-ul-Uloom, Karachi and a certified anatomist of Sukuk, Islamic Banking & Finance.',
      detail1: "He is a seasoned professional with over 12 & 8 years of working experience from writing Fatawa to serving as a Shariah Advisor of the leading financial institutions of Pakistan respectively.He has blend experience of providing Shariah consultancy to four (4) Takaful Companies as Shariah Advisor since 2015 and also served as Shariah Advisor of the Tier 2 Mudharabah Sukuk issued by Meezan Bank Limited. Currently he is also the Shariah Board Member at National Bank of Pakistan (Aitamad Islamic).",
      detail2: "He also has 7 years’ working experience with leading Auditing firms like A.F.F (PWC) & Deloitte Pakistan, as a Shariah Consultant & Head of Shariah Audit and has supervised various Shariah Audits of renowned Islamic Banks, Takaful Companies and Sukuks under the audit firm’s umbrella. He knows the practical problems being faced by the Islamic Financial InstitutionMufti Sb has qualified Takhassus (specialization) in Islamic Jurisprudence and Dar se Nizami from Jamia Darul Uloom, Karachi and a certified anatomist of Sukuks, Islamic Banking & Finance. He is also pursuing MBA besides having a bachelor’s degree in Economics from the University of Karachi that makes him the perfectly blended professional in the field of Islamic Finance & Takaful.He is a teacher of Hadith and Fiqh (Islamic Jurisprudence) at Jamia Dar-ul-Uloom, Karachi besides teaching in various leading Islamic educational institutions like IBA CEIF & Center for Islamic Economic since 2011.",
      animation: 'horizontal',
      caranim: "up",
      image: `${process.env.REACT_APP_ASSETS_URL}Assets/img/mufti.jpg`
  },

  {
      key: "2",
      name: 'Dr. Mufti Irshad Ahmad Aijaz',
      designation: 'Chairman Shariah Board',
      detail: 'Dr. Mufti Irshad Ahmad Aijaz holds Shahadat-ul-Aalamia (Masters in Arabic and Islamic Studies) and Takhassus fi al-Ifta (Specialization in Islamic Jurisprudence and Fatwa) from Jamia Dar-ul-Uloom, Karachi. He has also completed his MBA in finance  and a PhD. in Islamic Studies.',
      detail1: "He regularly delivers lectures on Islamic Economics and Finance at different forums and educational institutions including National Institute of Banking and Finance, IBA Karachi and Centre of Islamic Economics (a division of Jamia Dar-ul-Uloom, Karachi).",
      animation: 'vertical',
      caranim: "up",
      image: `${process.env.REACT_APP_ASSETS_URL}Assets/img/irshad.jpg`
  },

  {
      key: "3",
      name: 'Dr. Mufti Muhammad Younas Ali',
      designation: 'Shariah Board Member',
      detail: '   Dr. Mufti Muhammad Yunas Ali holds a PHD degree in Islamic Banking & Finance from University of Karachi. His Ph.D. dissertation titled “Shari’ah non-compliance risk and its impact on Islamic Banking”. He already possesses a Master’s degree in Islamic Banking & Finance from Sheikh Zayed Islamic Centre, University of Karachi. Religiously, he has completed Takhassus-fil-Fiqh wal-Ifta (Specialization in Islamic Jurisprudence and Fatwa Writing) and also completed Al-Shahadul Aalamiyyah (Equivalent to Masters in Arabic and Islamic Sciences) from Tanzeem-ul-Madaris Ahle-Sunnat Pakistan and got 3rd. position in all Pakistan. He has over 17 years of experience in the field of Research and Ifta.',
      detail1: " He is also a visiting lecturer in Sheikh Zayed Islamic Centre of University of Karachi, Islamic Learning Department – Faculty of Islamic Studies in University of Karachi, Al. Manaar Islamic Research Centre, and Jamiah Dar-ul-Uloom Memon Karachi MA Jinnah Road.",
      detail2: "    He has a remarkable academic record and written various Books including Interpretation and Marginal Notes on “Jaddul Mumtar” (Arabic commentary of Alahazrat Imam Ahmad Raza Khan on Radd - ul - Muhtar / Fatawa Shami) and “Bahar e Shariat” (Complete Collection of Islamic Laws), booklets and research papers which are published in HEC approved journals as well.",
      animation: 'horizontal',
      caranim: "up",
      image: `${process.env.REACT_APP_ASSETS_URL}Assets/img/younus.jpg`
  },
  {
      key: "4",
      name: 'Ayatullah Sheikh Shabbir Hasan Lakhani (Maisami)',
      designation: ' Chairman Shariah Board',
      detail: '  Ayatullah Sheikh Shabbir Hasan Lakhani (Maisami) holds a Master`s degree in Arabic and Islamic Sciences and a Sanad of Ijtehad, received from Ayatollah Sheikh Mohammad Hadi Marifat and Ayatollah Syed Muhammed Kazim Hairee. He specializes in areas such as the Islamic Education System, Islamic Economic System, Islamic Banking System, and Islamic Justice System. He has also attended Durs-e-Kharej (Ijtehad) of renowned scholars such as Ayatollah Waheed-e-Khorasani, Ayatollah Syed Mohammad Kazim Hairee, and Ayatollah Sheikh Mohammad Hadi Marifat. Additionally, he has received Ijazas of Umur-e-Hisbeyah from prominent figures like Ayatollah Aluzma Syed Mohammad Raza Gulpaygani, Ayatollah Aluzma Sheikh Nasir Makarim Shirazi, and Ayatollah Aluzma Sheikh Fazel Lankarani. Apart from his academic achievements, he established offices in multiple cities and completed three years towards an MBBS degree at Dow Medical College Karachi in 1981. He completed his intermediate education at Lawrence College, Murree, and matriculation at Habib Public School, Karachi.',
      detail1: "  Ayatullah Sheikh Shabbir Hasan Lakhani (Maisami) is an accomplished Islamic scholar with a wide range of achievements. He co-founded and has been managing the Zahra (S.A.) Academy for over 35 years. Additionally, he introduced the first-ever summer camps, Mazhab Shinasi, which have over 25,000 alumni worldwide. He is also a prolific writer, having authored six books in three languages, and serves as a Shariah Advisor to various organizations, including the State Bank of Pakistan and the Halal Food Authority Pakistan. Moreover, he is a member of several boards, including the Board of Governors of Halal Food Authority Pakistan and serves as an advisor to the Chairman of Wefaq-ul-Madaris Shia, Pakistan.Ayatullah Sheikh Shabbir Hasan Lakhani also has experience in the media industry, having been the Chief Operating Officer of Such TV from 2010 to 2012. Under his leadership, the channel developed a vision and strategy and broadcasted television programs within a year of its inception. He was responsible for profit and loss management and developed a team of experienced professionals under difficult financial constraints. Furthermore, he established offices in multip cities.",
      animation: 'horizontal',
      caranim: "up",
      image: `${process.env.REACT_APP_ASSETS_URL}Assets/img/ayatullah.jpg`
  },
  {
      key: "5",
      name: 'Dr. Irum Saba',
      designation: 'Shariah Board Technical Member',
      detail: '    Dr. Irum Saba has more than 17 years of progressive regulatory, research, training, and teaching experience with organizations of international repute.Currently, she is in Institute of Business Administration(IBA), Karachi working as an Associate Professor in the Department of Finance and Program Director of MS Islamic Banking and Finance.She has the honor to be the first Pakistani female to be part of the International Shariah Advisory Board in South East Asia and Middle East.Recently, she has been awarded the title of WOMENi Pakistan in recognition of her contribution to the Islamic Finance Industry in Pakistan and internationally. Furthermore, Dr.Irum Saba has also been included in the prestigious list of top 10 Influential Women in Islamic Business & Finance 2021, while attaining the honor of being the first female whose name has been added in the register of Shariah Advisors by SECP as per the Shariah Advisors Regulations, 2017.',
      animation: 'vertical',
      caranim: "up",
      image: `${process.env.REACT_APP_ASSETS_URL}directors/irum.jpg`
  },
]

export const LifeAtSalaamAmenitiesData = [
  {
    key: "1",
    heading: "Fitness Center",
    description:
      "At Salaam Family Takaful, we prioritize the well-being of our employees by providing state-of-the-art gym facilities. Our fully equipped gym is designed to support physical health and foster a positive work-life balance. From cardio equipment to strength training machines, our gym is a space where employees can rejuvenate and maintain their fitness goals. We also organize fitness classes and wellness challenges, promoting a healthy and active lifestyle among our team members.",
    images: [
      "https://asset.salaamtakaful.com/GeneralAssets/Assets/lifeatsalaam/FitnessCenter/1.jpg",
      "https://asset.salaamtakaful.com/GeneralAssets/Assets/lifeatsalaam/FitnessCenter/2.jpg",
      "https://asset.salaamtakaful.com/GeneralAssets/Assets/lifeatsalaam/FitnessCenter/3.jpg",
      "https://asset.salaamtakaful.com/GeneralAssets/Assets/lifeatsalaam/FitnessCenter/4.jpg",
      "https://asset.salaamtakaful.com/GeneralAssets/Assets/lifeatsalaam/FitnessCenter/5.jpg",
    ],
    speed: 2000
  },
  {
    key: "2",
    heading: "Recreation Room",
    description:
      "Salaam Family Takaful understands the significance of providing moments of relaxation and rejuvenation for our employees. Our thoughtfully designed recreation room is a retreat for unwinding and recharging. Immerse yourself in a game of pool, find solace in the quiet reading corner, enjoy the therapeutic benefits of our massage chair, or engage in friendly competition with activities like darts, board games, and table tennis. This space is carefully crafted to foster camaraderie and relaxation, ensuring a dynamic and friendly workplace culture. Join us in creating lasting memories through the array of regular events and activities that add vibrancy to our shared work environment.",
    images: [
      "https://asset.salaamtakaful.com/GeneralAssets/Assets/lifeatsalaam/RecreationRoom/1.jpg",
      "https://asset.salaamtakaful.com/GeneralAssets/Assets/lifeatsalaam/RecreationRoom/2.jpg",
      "https://asset.salaamtakaful.com/GeneralAssets/Assets/lifeatsalaam/RecreationRoom/3.jpg",
      "https://asset.salaamtakaful.com/GeneralAssets/Assets/lifeatsalaam/RecreationRoom/4.jpg",
      "https://asset.salaamtakaful.com/GeneralAssets/Assets/lifeatsalaam/RecreationRoom/5.jpg",
      "https://asset.salaamtakaful.com/GeneralAssets/Assets/lifeatsalaam/RecreationRoom/6.jpg",
    ],
    speed: 3000
  },
  {
    key: "3",
    heading: "Segregated Prayer Area",
    description:
      "At Salaam Family Takaful, inclusivity is at the heart of our workplace ethos. Recognizing the diverse cultural and religious backgrounds of our esteemed team, we have established a Segregated Prayer Area within our premises. This dedicated space is meticulously designed to facilitate the diverse spiritual needs of our employees, allowing them to observe their religious practices with ease and convenience. We firmly believe that fostering an inclusive and respectful workplace culture is not only an integral part of our identity but also paramount to the overall well-being of our valued team members.",
    images: [
      "https://asset.salaamtakaful.com/GeneralAssets/Assets/lifeatsalaam/SegregatedPrayerArea/PR1.jpg",
      "https://asset.salaamtakaful.com/GeneralAssets/Assets/lifeatsalaam/SegregatedPrayerArea/PR2.jpg",
      "https://asset.salaamtakaful.com/GeneralAssets/Assets/lifeatsalaam/SegregatedPrayerArea/PR3.jpg",
      "https://asset.salaamtakaful.com/GeneralAssets/Assets/lifeatsalaam/SegregatedPrayerArea/PR4.jpg",
    ],
    speed: 4000
  },
  {
    key: "4",
    heading: "Girls’ Common Room",
    description:
      "In our commitment to providing an environment that supports the diverse needs of our workforce, Salaam Family Takaful proudly presents the Girls Common Room. This exclusive space is tailored to cater to the specific preferences and requirements of our female team members. Whether it's a comfortable area for breaks, collaborative discussions, or a private sanctuary for personal moments, the Girls Common Room is a reflection of our dedication to creating an inclusive workplace that prioritizes the comfort and empowerment of every individual within our team.",
    images: [
      "https://asset.salaamtakaful.com/GeneralAssets/Assets/lifeatsalaam/GIRLSCOMMONROOM/Girls-Common-Room.jpg",
    ],
    speed: 5000
  },
];
export const LifeAtSalaamGrowthData = [
  {
    key: "1",
    heading: "Events",
    images: [
      "https://asset.salaamtakaful.com/GeneralAssets/Assets/lifeatsalaam/EVENTS/1.jpg",
      "https://asset.salaamtakaful.com/GeneralAssets/Assets/lifeatsalaam/EVENTS/2.jpg",
      "https://asset.salaamtakaful.com/GeneralAssets/Assets/lifeatsalaam/EVENTS/3.jpg",
      "https://asset.salaamtakaful.com/GeneralAssets/Assets/lifeatsalaam/EVENTS/4.jpg",
      "https://asset.salaamtakaful.com/GeneralAssets/Assets/lifeatsalaam/EVENTS/5.jpg",
      "https://asset.salaamtakaful.com/GeneralAssets/Assets/lifeatsalaam/EVENTS/6.jpg",
      "https://asset.salaamtakaful.com/GeneralAssets/Assets/lifeatsalaam/EVENTS/7.jpg",
      "https://asset.salaamtakaful.com/GeneralAssets/Assets/lifeatsalaam/EVENTS/8.jpg",
      "https://asset.salaamtakaful.com/GeneralAssets/Assets/lifeatsalaam/EVENTS/9.jpg",
      "https://asset.salaamtakaful.com/GeneralAssets/Assets/lifeatsalaam/EVENTS/10.jpg",
      "https://asset.salaamtakaful.com/GeneralAssets/Assets/lifeatsalaam/EVENTS/11.jpg",
      "https://asset.salaamtakaful.com/GeneralAssets/Assets/lifeatsalaam/EVENTS/12.jpg",
    ],
    data: [
      {
        heading: "Team Building Events",
        description:
          "At Salaam Family Takaful, we believe in the power of team spirit. Regular events, such as team-building activities and casual gatherings, are organized to strengthen the bonds among our employees. These events provide opportunities for collaboration, communication, and a shared sense of purpose, contributing to a positive and dynamic work environment.",
      },
      {
        heading: "Special Events",
        description:
          "We love to celebrate milestones and create lasting memories. Salaam Family Takaful hosts special events throughout the year, including annual parties, holiday celebrations, and significant milestones. These events bring our team together in a festive spirit, fostering a sense of belonging and camaraderie that extends beyond the workplace.",
      },
      {
        heading: "Community Engagement",
        description:
          "Salaam Family Takaful is committed to making a positive impact beyond the workplace. We actively engage with the community through various initiatives, including charity drives, volunteering, and partnerships with local organizations. By contributing to the community, we not only fulfill our corporate social responsibility but also strengthen the bonds between our employees and the wider society.",
      },
    ],
    speed: 1500
  },
  {
    key: "2",
    heading: "Engagments",
    description:
      "Salaam Family Takaful understands the significance of providing moments of relaxation and rejuvenation for our employees. Our thoughtfully designed recreation room is a retreat for unwinding and recharging. Immerse yourself in a game of pool, find solace in the quiet reading corner, enjoy the therapeutic benefits of our massage chair, or engage in friendly competition with activities like darts, board games, and table tennis. This space is carefully crafted to foster camaraderie and relaxation, ensuring a dynamic and friendly workplace culture. Join us in creating lasting memories through the array of regular events and activities that add vibrancy to our shared work environment.",
    images: [
      "https://asset.salaamtakaful.com/GeneralAssets/Assets/lifeatsalaam/ENGAGEMENT/1.jpg",
      "https://asset.salaamtakaful.com/GeneralAssets/Assets/lifeatsalaam/ENGAGEMENT/2.jpg",
      "https://asset.salaamtakaful.com/GeneralAssets/Assets/lifeatsalaam/ENGAGEMENT/3.jpg",
      "https://asset.salaamtakaful.com/GeneralAssets/Assets/lifeatsalaam/ENGAGEMENT/4.jpg",
      "https://asset.salaamtakaful.com/GeneralAssets/Assets/lifeatsalaam/ENGAGEMENT/5.jpg",
    ],
    data: [
      {
        heading: "Walkathon",
        description:
          "Promoting employee well-being is a priority at Salaam Family Takaful. Our annual walkathons and wellness challenges encourage a healthy and active lifestyle among our team members.These events not only contribute to physical fitness but also create a sense of community and teamwork, fostering a positive and supportive workplace culture. ",
      },
      {
        heading: "Awards and Recognitions",
        description:
          "Salaam Family Takaful takes pride in recognizing and celebrating the achievements of our employees. Whether it's individual milestones, outstanding contributions, or industry awards, we showcase and acknowledge the accomplishments of our team members. This culture of appreciation motivates our employees and reinforces the value we place on their dedication and hard work.",
      },
    ],
    speed: 3000
  },
];

export const LifeAtSalaamLearningData = [
  {
    key: "1",
    heading: "Professional Mastery",
    description:
      "At Salaam Family Takaful, we are dedicated to sculpting the expertise of our team through targeted Professional Skill Development. This initiative focuses on the mastery of tools critical to our operations, including project management, Excel, and artificial intelligence (AI). Our commitment extends to comprehensive training programs, ensuring our employees not only adapt to industry trends but become proficient leaders in utilizing these technologies.",
    images: [
      "https://asset.salaamtakaful.com/GeneralAssets/Assets/lifeatsalaam/PROFESSIONALMASTERY/1.jpg",
      "https://asset.salaamtakaful.com/GeneralAssets/Assets/lifeatsalaam/PROFESSIONALMASTERY/2.jpg",
      "https://asset.salaamtakaful.com/GeneralAssets/Assets/lifeatsalaam/PROFESSIONALMASTERY/3.jpg",
    ],
    speed: 2000
  },
  {
    key: "2",
    heading: "Nurturing Soft Skills",
    description:
      "Salaam Family Takaful places a premium on continuous improvement through our Skill Enhancement programs, specifically tailored to refine the soft skills essential in the Takaful industry. By focusing on interpersonal dynamics, communication, adaptability, and problem-solving, we empower our team to excel not only in their roles but also in contributing to the harmonious and collaborative culture at the heart of our organization's success.",
    images: [
      "https://asset.salaamtakaful.com/GeneralAssets/Assets/lifeatsalaam/NurturingSoftSkills/1.jpg",
      "https://asset.salaamtakaful.com/GeneralAssets/Assets/lifeatsalaam/NurturingSoftSkills/2.jpg",
      "https://asset.salaamtakaful.com/GeneralAssets/Assets/lifeatsalaam/NurturingSoftSkills/3.jpg",
      "https://asset.salaamtakaful.com/GeneralAssets/Assets/lifeatsalaam/NurturingSoftSkills/4.jpg",
      "https://asset.salaamtakaful.com/GeneralAssets/Assets/lifeatsalaam/NurturingSoftSkills/5.jpg",
    ],
    speed: 3500
  },
];