import React, { Fragment } from "react";
import Fade from "react-reveal/Fade";
import Shariahtext from "./Shariahtext";
// import Tabss from "./Tabs";
import Advisor from "./Advisor";

function Shariahbanner() {
  return (
    <Fragment>
      <div className="col-sm-12 heads govbanner">
        <Fade up delay={500}>
          <h2 className="oswald">Shariah Compliance</h2>
        </Fade>
      </div>

      <div className="container tabss motor newShariah">
        <div className="row">
          <div className="col-sm-12 heads text mymaxheight">
            <Shariahtext />
          </div>

          <div className="col-sm-12 heads text mymaxheight">
            {/* <Tabss /> */}
            <Advisor />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Shariahbanner;