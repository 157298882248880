import React from "react";
import { Fragment } from "react";
import ArticleSingleNews from "../Components/Articel/ArticleSingleNews";

function ArticleSingleNewsPage() {
    return (
        <Fragment>
            <div className="container-fluid faderight">
                <div className="row">
                    <ArticleSingleNews />
                </div>
            </div>
        </Fragment>
    );
}

export default ArticleSingleNewsPage;
