import React from "react";
import { Fragment } from "react";
import Fade from "react-reveal/Fade";
import Overview from "../../Assets/newoverview.jpeg"
import { Link } from "react-router-dom";

function ProductText() {
  return (
    <Fragment>
      <div className="sub productetxt iinndiivuaaal">
        <div className="row">
          <Fade left delay={500}>
            <div className="col-sm-8 writeus">
              <h2>An Overview of Our Individual Life Takaful Plan</h2>
              <p>
                Elevate your financial future with our cutting-edge Individual
                Life Takaful Plan, offering unparalleled protection and peace of
                mind. Our Shari'ah-compliant solution provides exceptional
                coverage, including death benefits, accidental death, and
                disability, tailored to meet your unique needs. Enjoy 100%
                allocation for your investments to supercharge your growth. Our
                streamlined digital onboarding and servicing journey guarantees
                a seamless experience from start to finish. Regulated and
                approved by SECP, our plan features a simplified charge
                structure with breakeven from day one. Benefits of zero
                withdrawal fees and immediate access to funds, ensuring you
                protect your future while aligning with your values. Choose our
                Individual Life Takaful Plan for a customizable, comprehensive
                solution that adapts to your life’s journey.
              </p>
              <br />
              <p className="browchure">
                <button>
                  <Link
                    //  to={`${process.env.REACT_APP_ASSETS_URL}Assets/pdf/IndividualBrochure.pdf`}
                    to="/assets/IndividualBroucher.pdf"
                    target="_blank"
                    className="combtn"
                  >
                    {" "}
                    Download Brochure
                  </Link>
                </button>
              </p>
            </div>
          </Fade>

          <Fade right delay={500}>
            <div className="col-sm-4 writeus indivuuaal">
              <img src={Overview} alt="pdf" className="indivualBrochure" />
            </div>
          </Fade>
        </div>
      </div>
    </Fragment>
  );
}

export default ProductText;
