import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import Fade from "react-reveal/Fade";
import FundPricesTable from "./FundPricesTable";

function FundPricesBanner() {
  const [fundPrices, setFundPrices] = useState();

  const getFunds = async () => {
    try {
      const response = await axios.get(
        "https://ilfunds.salaamlifetakaful.com/api/CashValue/CashValues"
      );
      setFundPrices(response.data);
    } catch (error) {
      console.error("Error fetching fund prices:", error);
    }
  };

  useEffect(() => {
    getFunds();
  }, []);
  console.log(fundPrices, "<==============fundPrice");
  return (
    <Fragment>
      <div className="container tabss governancemain motor whistle">
        <div className="row">
          <div className="col-sm-12 heads govbanner">
            <Fade up delay={500}>
              <h2 className="oswald">Fund Prices</h2>
            </Fade>
          </div>

          <div className="col-sm-12 heads text mymaxheight complainbox whistle fundtable">
            <FundPricesTable fundPrices={fundPrices} />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default FundPricesBanner;
