import React, { Fragment, useEffect } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Desktopnav({ change, setChange }) {
  useEffect(() => {
    const href = window.location.href.substring(
      window.location.href.lastIndexOf("#") + 1
    );
    const element = document.getElementById(href);

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [change]);
const handleScroll = () => {
  setChange(!change);
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
};
return (
  <Fragment>
    <Navbar expand="lg" className="bg-body-tertiary">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <NavDropdown title="About Us" id="basic-nav-dropdown">
            <NavDropdown.Item>
              <Link
                to="/whoweare#mission&vision"
                onClick={() => setChange(!change)}
              >Vision & Mission
              </Link>
            </NavDropdown.Item>

            <NavDropdown.Item>
              <Link to="/whoweare#ceo" onClick={() => setChange(!change)}>
                CEO Message
              </Link>
            </NavDropdown.Item>

            <NavDropdown.Item>
              <Link to="/company-detail" onClick={() => setChange(!change)}>
                Company Details
              </Link>
            </NavDropdown.Item>
            {/* <NavDropdown.Item>
                <Link to="/whoweare#shariah" onClick={() => setChange(!change)}>
                  Shariah Advisor
                </Link>
              </NavDropdown.Item> */}
            <NavDropdown.Item>
              <Link to="/shariah" onClick={handleScroll}>
                Shariah
              </Link>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <Link to="/whoweare#coc" onClick={() => setChange(!change)}>
                Code Of Conduct
              </Link>
            </NavDropdown.Item>
          </NavDropdown>

          <NavDropdown title="Governance" id="basic-nav-dropdown">
            <NavDropdown.Item href="">
              <Link
                to="/governance#directors"
                onClick={() => setChange(!change)}
              >
                Board of Directors
              </Link>
            </NavDropdown.Item>
            {/* <NavDropdown.Item href="">
                <Link
                  to="/governance#managment"
                  onClick={() => setChange(!change)}
                >
                  Management Team
                </Link>
              </NavDropdown.Item> */}
            {/* <NavDropdown.Item href="">
                <Link
                  to="/governance#auditor"
                  onClick={() => setChange(!change)}
                >
                  Name of Auditor{" "}
                </Link>{" "}
              </NavDropdown.Item>
              <NavDropdown.Item href="">
                {" "}
                <Link to="/governance#legal" onClick={() => setChange(!change)}>
                  Legal Advisor
                </Link>
              </NavDropdown.Item> */}
          </NavDropdown>

          <NavDropdown title="Products" id="basic-nav-dropdown">
            <NavDropdown.Item href="">
              <Link
                to="/individuals"
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }}
              >
                Individual Product
              </Link>
            </NavDropdown.Item>

            <NavDropdown.Item href="">
              <Link
                to="/group"
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }}
              >
                Group Product
              </Link>
            </NavDropdown.Item>

            {/* <NavDropdown.Item href="">
              <Link
                to="/group-saving"
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }}
              >
                Group Savings
              </Link>
            </NavDropdown.Item> */}
          </NavDropdown>

          {/* <NavDropdown title="Products" id="basic-nav-dropdown">

              <NavDropdown.Item href=""><Link to="/individuals" onClick={() => setChange(!change)}>Individual Products </Link> </NavDropdown.Item>        

            </NavDropdown> */}

          <NavDropdown title="Investor Relations" id="basic-nav-dropdown">
            {/* 
              <NavDropdown.Item href=""> Investor Complaints</NavDropdown.Item>
              <NavDropdown.Item href=""> Investment Performance Report</NavDropdown.Item>
              <NavDropdown.Item href=""> Individual Life - Unit Prices </NavDropdown.Item>
              <NavDropdown.Item href=""> Bancatakaful - Unit Prices	</NavDropdown.Item>
              */}
            <NavDropdown.Item href="">
              <Link
                style={{ padding: 0 }}
                to="/governance#pattern"
                onClick={() => setChange(!change)}
              >
                Pattern of Shareholding
              </Link>
            </NavDropdown.Item>
            <NavDropdown title="Funds" id="basic-nav-dropdown">
              {/* <NavDropdown.Item href="">
                  <Link
                    to="/fund-prices"
                    onClick={() => {
                      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    }}
                  >
                    {" "}
                    Overview{" "}
                  </Link>
                </NavDropdown.Item> */}

              <NavDropdown.Item href="">
                <Link
                  to="/fund-prices"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                >
                  {" "}
                  Funds Prices{" "}
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item href="">
                {" "}
                <Link
                  to="/past-prices"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                >
                  {" "}
                  Past Prices{" "}
                </Link>
              </NavDropdown.Item>
{/* 
              <NavDropdown.Item href="">
                <Link
                  to="/fund-prices"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                >
                  {" "}
                  Funds Performance{" "}
                </Link>
              </NavDropdown.Item> */}

              <NavDropdown.Item href="">
                <Link
                  to="/fmr-report"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                >
                  {" "}
                  Fund Manager Reports{" "}
                </Link>
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Financial Statements" id="basic-nav-dropdown">
              <NavDropdown.Item href="">Coming Soon</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown.Item
              href="https://sdms.secp.gov.pk/"
              target="_blank"
              rel="noreferrer"
            >
              SECP's Service Desk
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link>
            <Link
              to="/life-at-salaam"
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              }}
            >
              Life@Salaam
            </Link>
          </Nav.Link>
          <Nav.Link>
            <Link
              to="/esg"
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              }}
            >
              Sustainability
            </Link>
          </Nav.Link>
          {/* <NavDropdown title="Financial Statements" id="basic-nav-dropdown">
                <NavDropdown.Item href="">
                  Financial Reports 
                </NavDropdown.Item>
              </NavDropdown> */}
          <NavDropdown title="Downloads" id="basic-nav-dropdown">
            <NavDropdown title="Individual Life " id="basic-nav-dropdown" className="individualDropdown">
              <NavDropdown.Item
                href={`${process.env.REACT_APP_ASSETS_URL}/Assets/pdf/Individual.pdf`}
                target="_blank"
                rel="noreferrer"
              >
                Shariah Certificates of Products{" "}
              </NavDropdown.Item>
              <NavDropdown.Item
                target="_blank"
                href={`${process.env.REACT_APP_ASSETS_URL}Assets/pdf/IndividualBrochure.pdf`}
                rel="noreferrer" >
                Product Brochures
              </NavDropdown.Item>
              {/* <NavDropdown.Item href=""> Forms </NavDropdown.Item>
                <NavDropdown.Item href=""> PMD </NavDropdown.Item>
                <NavDropdown.Item href=""> Medical network </NavDropdown.Item>
                <NavDropdown.Item href=""> How to buy online </NavDropdown.Item>
                <NavDropdown.Item href={broucherPdf1} target="_blank">{" "}Broacher{" "}</NavDropdown.Item> */}
            </NavDropdown>

            <NavDropdown title="Group Life " id="basic-nav-dropdown" className="GroupDropdown">
              <NavDropdown.Item
                href={`${process.env.REACT_APP_ASSETS_URL}Assets/pdf/Term.pdf`}
                target="_blank"
              >
                Shariah Certificates of Products (Corporate){" "}
              </NavDropdown.Item>
              <NavDropdown.Item
                href={`${process.env.REACT_APP_ASSETS_URL}Assets/pdf/GroupBrochure.pdf`}
                target="_blank"
              >
                Product Brochures
              </NavDropdown.Item>
              {/* 
                <NavDropdown.Item href=""> Forms </NavDropdown.Item>
                <NavDropdown.Item href=""> PMD </NavDropdown.Item>
                <NavDropdown.Item href=""> Medical network </NavDropdown.Item>
                <NavDropdown.Item href="">Discounted OPD centers</NavDropdown.Item> 
                <NavDropdown.Item href=""> How to buy online </NavDropdown.Item> 
                <NavDropdown.Item href={broucherPdf} target="_blank">Broacher</NavDropdown.Item> 
                */}
            </NavDropdown>

            {/* <NavDropdown title="Waqf Deed" id="basic-nav-dropdown">
              <NavDropdown.Item
                href={`${process.env.REACT_APP_ASSETS_URL}Assets/pdf/Waqf.pdf`}
                target="_blank"
              >
                {" "}
                Waqf Deed{" "}
              </NavDropdown.Item>
            </NavDropdown> */}

            {/* <NavDropdown title="Waqf Rules" id="basic-nav-dropdown">
              <NavDropdown.Item
                href={`${process.env.REACT_APP_ASSETS_URL}Assets/pdf/WaqfRules.pdf`}
                target="_blank"
              >
                {" "}
                Waqf Rules{" "}
              </NavDropdown.Item>
            </NavDropdown> */}

            {/* <NavDropdown title="Takaful Rules" id="basic-nav-dropdown">
                <NavDropdown.Item href=""> Takaful Rules </NavDropdown.Item>
              </NavDropdown> */}

            {/* <NavDropdown title="Unclaimed Benefits" id="basic-nav-dropdown">
                <NavDropdown.Item href="">
                  {" "}
                  Unclaimed Benefits{" "}
                </NavDropdown.Item>
              </NavDropdown> */}

            {/* <NavDropdown title="Takaful Booklet" id="basic-nav-dropdown">
              <NavDropdown.Item href=""> Takaful Booklet </NavDropdown.Item>
            </NavDropdown> */}

            <NavDropdown title="Policies" id="basic-nav-dropdown" className="policyDropdown">
              <NavDropdown.Item>
                <Link to="/whoweare#coc" onClick={() => setChange(!change)}>
                  {" "}
                  Policies
                </Link>{" "}
              </NavDropdown.Item>

              <NavDropdown.Item href="">
                {" "}
                <Link
                  to="/whistle-blowing"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  }}
                >
                  Whistle Blowing Form
                </Link>
              </NavDropdown.Item>
            </NavDropdown>
          </NavDropdown>

          <NavDropdown title="Media Center" id="basic-nav-dropdown">
            <NavDropdown.Item>
              {" "}
              <Link
                to="/takaful-videos"
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }}
              >
                Takaful Videos{" "}
              </Link>{" "}
            </NavDropdown.Item>
            <NavDropdown.Item href="">
              <Link
                to="/newsnevents"
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }}
              >
                {" "}
                PR & News Items{" "}
              </Link>
            </NavDropdown.Item>
            <NavDropdown.Item href="/articles">
              <Link
                to="/articles"
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }}
              >
                {" "}
                Articles{" "}
              </Link>
            </NavDropdown.Item>
          </NavDropdown>

          {/* <NavDropdown title="E Services" id="basic-nav-dropdown"> */}
          {/* 
              <NavDropdown.Item href="https://online.salaamtakaful.com/e-verify.aspx">
                <Link to="https://online.salaamtakaful.com/e-verify.aspx" target="_blank" >Certificate Verification</Link>
              </NavDropdown.Item> 
              */}
          {/* <NavDropdown.Item>
              {" "}
              <Link
                to="/complain-box"
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }}
              >
                Complaint Form
              </Link>
            </NavDropdown.Item>

            <NavDropdown.Item href="">
              {" "}
              <Link
                to="/whistle-blowing"
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }}
              >
                Whistle Blowing Form
              </Link>
            </NavDropdown.Item>
          </NavDropdown> */}

          <a
            href="https://portals.salaamlifetakaful.com/Claim/LodgeClaim_Dashboard"
            // to="/dashboard/claim-tab"
            target="_blank"
            rel="noreferrer"
            className="buttoncalim"
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }}
          >
            Claim Lodge
          </a>

          {/* <Nav.Link> */}
          {/* <div className="gogleTransate">

              <div id="google_translate_element"></div>
            </div> */}

          {/* </Nav.Link> */}

          {/* <NavDropdown title="Online Portals" id="basic-nav-dropdown">
              <NavDropdown.Item href=""> Agent Portal </NavDropdown.Item>
            </NavDropdown> */}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </Fragment>
);
}
