import React from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { News } from "../../Data/Data";


function Recentnews() {
  return (
    <Fragment>
      <div className="sidenews">
        <h2 className="posthead">Recent Post</h2>
      
        {News.slice(0, 5).map((data) => {
          const trimmedContent = data?.content.split(" ").slice(0, 15).join(" ") + (data.content.split(" ").length > 15 ? "..." : "");
          return(
            <Link to={`/news/${data.key}`} onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: "smooth" }); }} key={data.key}>
              <div className='row ArticlesAndBlogs'>
                <div className='col-sm-5'>
                  <img src={data?.feature} alt="asd" />
                </div>
                <div className='col-sm-7'>
                  <h4>{trimmedContent}</h4>
                  <p>{data?.Date}</p>
                </div>
              </div>
            </Link>
          )
          
        })}
      </div>
    </Fragment>
  );
}

export default Recentnews;
