import React, { Fragment } from "react";
import Flip from "react-reveal/Flip";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import { Button } from "antd";

function Appsdownload() {
  return (
    <Fragment>
      <div className="container donwloadapps">
        <div className="row">
          <div className="col-sm-6">
            <Fade top delay={400}>
              <h2>Salaam Life & Savings APP, an Insurtech Ecosystem</h2>
            </Fade>
            <Fade top delay={600}>
              {" "}
              <p>
                Get access to our multi-functional Salaam App and avail all the
                benefits including:
              </p>
            </Fade>
            <ul>
              <Flip top delay={800}>
                {" "}
                <li>
                  <img
                    src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/checks.png`}
                    alt=""
                  />{" "}
                  Free Withdrawal Facility
                </li>
              </Flip>
              <Flip top delay={1000}>
                {" "}
                <li>
                  <img
                    src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/checks.png`}
                    alt=""
                  />{" "}
                  One Click Contribution Payment
                </li>
              </Flip>
              <Flip top delay={1200}>
                {" "}
                <li>
                  <img
                    src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/checks.png`}
                    alt=""
                  />{" "}
                  Easy Claim Lodging
                </li>
              </Flip>

              <Flip top delay={1400}>
                {" "}
                <li>
                  <img
                    src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/checks.png`}
                    alt=""
                  />{" "}
                  Real-time Cash Value monitoring of your Salaam Life & Savings Plan
                </li>
              </Flip>

              <Flip top delay={1600}>
                {" "}
                <li>
                  <img
                    src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/checks.png`}
                    alt=""
                  />{" "}
                  Free audio and Video OPD consultation with qualified doctors
                </li>
              </Flip>
              <Flip top delay={1800}>
                {" "}
                <li>
                  <img
                    src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/checks.png`}
                    alt=""
                  />{" "}
                  Enjoy Buy 1 get 1 free offer at 1200 merchants across Pakistan, and much more
                </li>
              </Flip>
            </ul>
            <div className="button-container app-buttons">
              {/* <Flip top delay={1400}>
                <Link to="/product">
                  <img
                    src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/playstore.png`}
                    alt="android"
                  />
                </Link>
              </Flip>
              <Flip top delay={1400}>
                <Link to="/product-ios">
                  <img 
                  src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/appstore.png`}
                  alt="apple" />
                </Link>
              </Flip> */}
              <Fade bottom delay={2200}>
                <Button>Mobile App Coming Soon</Button>
              </Fade>
            </div>
          </div>
          <div className="col-sm-6 mobiles">
            <Fade down delay={1400}>
              {" "}
              <img
                src={`${process.env.REACT_APP_ASSETS_URL}Assets/img/mobilsapps.png`}
                alt="apps"
              />
            </Fade>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Appsdownload;
