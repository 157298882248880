import React from "react";
import { Fragment } from "react";
import Fade from "react-reveal/Fade";
import { suplementryDataGroup } from "../../Data/Data";

function Suplimentaryriders() {
  return (
    <Fragment>
      <div className="container-fluid shares riders">
        <div className="row newRow" >
          <Fade right delay={500}>
            <div className="col-sm-12 supliments">
              <h2>OUR PRODUCT DIFFERENTIATORS</h2>
            </div>
          </Fade>

          {suplementryDataGroup?.map((elem, ind) => (
            <Fade up delay={600}>
              <div className="col-sm-4 suplimentsicon">
                <div className="supliment">
                  <div className="row mySuppliment DIFFERENTIATORS">
                    <div className="col-sm-12">
                      <img src={elem?.image} alt="" className="sssuupplemnet" />{" "}
                    </div>
                    <div className="col-sm-12">
                      <h3>{elem?.heading}</h3>
                      <p>{elem?.text}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          ))}
        </div>
      </div>
    </Fragment>
  );
}

export default Suplimentaryriders;
