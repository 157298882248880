import React from "react";
import { Fragment, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
// import { RxCross2 } from "react-icons/rx";
import Fade from "react-reveal/Fade";
import Downloads from "./Downloads";
import { useNavigate } from "react-router-dom";
import Flip from "react-reveal/Flip";
import { Link } from "react-router-dom";
import InvestorRelation from "./InvestorRelation";

function Sidemenu({ show, setshow, change, setChange }) {
  const navigate = useNavigate();

  useEffect(() => {
    const href = window.location.href.substring(
      window.location.href.lastIndexOf("#") + 1
    );
    const element = document.getElementById(href);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [change]);

  return (
    <Fragment>
      <div className="mobileMenu">
        <div>
          <div className="container menuBar">
            <span onClick={() => setshow(false)}>{/* <RxCross2 /> */}</span>

            <Accordion flush>
              <Fade right delay={500}>
                <Accordion.Item eventKey="0">
                  <Accordion.Header> About Us </Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>
                        {" "}
                        <Link
                          to="/whoweare#mission&vision"
                          onClick={() => setChange(!change)}
                        >
                          Vision & Mission
                        </Link>{" "}
                      </li>
                      <li>
                        {" "}
                        <Link
                          to="/whoweare#ceo"
                          onClick={() => setChange(!change)}
                        >
                          CEO Message
                        </Link>{" "}
                      </li>
                      <li>
                        <Link
                          to="/company-detail"
                          onClick={() => setChange(!change)}
                        >
                          Company Details
                        </Link>
                      </li>
                      <li>
                        {" "}
                        <Link
                          to="/shariah"
                          onClick={() => setChange(!change)}
                        >
                          Shariah Advisor{" "}
                        </Link>{" "}
                      </li>
                      <li>
                        {" "}
                        <Link
                          to="/whoweare#coc"
                          onClick={() => setChange(!change)}
                        >
                          {" "}
                          Code Of Conduct
                        </Link>
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Fade>

              <Fade right delay={1000}>
                <Accordion.Item eventKey="1">
                  <Accordion.Header> Governance </Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>
                        <Link
                          to="/governance#directors"
                          onClick={() => setChange(!change)}
                        >
                          Board of Directors{" "}
                        </Link>{" "}
                      </li>
                      {/* <li>
                        {" "}
                        <Link
                          to="/governance#managment"
                          onClick={() => setChange(!change)}
                        >
                          {" "}
                          Management Committee{" "}
                        </Link>
                      </li>
                      <li>
                        {" "}
                        <Link
                          to="/governance#auditor"
                          onClick={() => setChange(!change)}
                        >
                          Name of Auditor{" "}
                        </Link>{" "}
                      </li>
                      <li>
                        {" "}
                        <Link
                          to="/governance#legal"
                          onClick={() => setChange(!change)}
                        >
                          Legal Advisor
                        </Link>
                      </li>
                      <li>
                        {" "}
                        <Link
                          to="/governance#pattern"
                          onClick={() => setChange(!change)}
                        >
                          Pattern of Shareholding{" "}
                        </Link>{" "}
                      </li> */}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Fade>

              <Fade right delay={1300}>
                <Accordion.Item eventKey="3">
                  <Accordion.Header> Products </Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>
                        <Link
                          to="/individuals"
                          onClick={() => setChange(!change)}
                        >
                          Individual Products
                        </Link>{" "}
                      </li>
                      <li>
                        {" "}
                        <Link to="/group" onClick={() => setChange(!change)}>
                          {" "}
                          Group Products{" "}
                        </Link>{" "}
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Fade>

              <Fade right delay={1600}>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>Investor Relations</Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>
                        {" "}
                        <InvestorRelation />
                      </li>
                      <li>
                        <Link
                          style={{ padding: 0 }}
                          to="/governance#pattern"
                          onClick={() => setChange(!change)}
                        >
                          Pattern of Shareholding
                        </Link>
                      </li>
                      <li>
                        <a
                          href="https://sdms.secp.gov.pk/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                          SECP's Service Desk
                        </a>{" "}
                      </li>
                      {/* <li>
                        <Link
                          to="/fund-prices"
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          {" "}
                          Funds Prices
                        </Link>{" "}
                      </li>
                      <li>
                        {" "}
                        <Link
                          to="/past-prices"
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          {" "}
                          Past Prices{" "}
                        </Link>
                      </li>
                      <li>
                        {" "}
                        <Link
                          to="https://sdms.secp.gov.pk/"
                          target="_blank"
                        >
                          {" "}
                          SECP's{" "}
                        </Link>
                      </li> */}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Fade>

              <Fade right delay={1900}>
                <ul className="esgSidebar">
                  <li>
                  <Link
              to="/life-at-salaam"
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              }}
            >
              Life@Salaam
            </Link>
                  </li>
                </ul>
              </Fade>

              <Fade right delay={2200}>
                <ul className="esgSidebar">
                  <li>
                    <Link
                      to="/esg"
                      onClick={() => {
                        window.scrollTo({
                          top: 0,
                          left: 0,
                          behavior: "smooth",
                        });
                      }}
                    >
                      Sustainabilty
                    </Link>
                  </li>
                </ul>
              </Fade>

              {/* <Fade right delay={1900}>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>Financial Statements</Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>
                        <Link to="">Financial Reports (PDF)</Link>
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Fade> */}

              <Fade right delay={2500}>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>Downloads</Accordion.Header>
                  <Accordion.Body>
                    <Downloads change={change} setChange={setChange} />
                  </Accordion.Body>
                </Accordion.Item>
              </Fade>

              <Fade right delay={2800}>
                <Accordion.Item eventKey="7">
                  <Accordion.Header>Media Center</Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>
                        <Link
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                          to="/takaful-videos"
                        >
                          Takaful Videos
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                          to="/newsnevents"
                        >
                          PR & News Items
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                          to="/articles"
                        >
                          Articles
                        </Link>
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Fade>

              <Fade right delay={3100}>
                <ul className="esgSidebar">
                  <li>
                  <a
            href="https://portals.salaamlifetakaful.com/Claim/LodgeClaim_Dashboard"
            // to="/dashboard/claim-tab"
            target="_blank"
            // className="buttoncalim"
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }}
          >
            Claim Lodge
          </a>
                  </li>
                </ul>
              </Fade>

              {/* <Fade right delay={2800}>
                <Accordion.Item eventKey="9">
                  <Accordion.Header>E Services</Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>
                        <Link
                          to="https://online.salaamtakaful.com/e-verify.aspx"
                          target="_blank"
                        >
                          {" "}
                          Certificate Verification
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/complain-box"
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          {" "}
                          Complaint Box
                        </Link>{" "}
                      </li>
                      <li>
                        {" "}
                        <Link
                          to="/whistle-blowing"
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        >
                          {" "}
                          Whistle Blowing Form{" "}
                        </Link>
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Fade> */}

              {/* <Fade right delay={3200}>
                <Accordion.Item eventKey="8">
                  <Accordion.Header>Online Portals</Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li>
                        <Link to="">Buy Online</Link>
                      </li>
                      <li>
                        <Link to="">Customer Portal Link</Link>
                      </li>
                      <li>
                        <Link to="">Claim Portal </Link>
                      </li>
                      <li>
                        <Link to="">Agent Portal</Link>
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Fade> */}
            </Accordion>
          </div>
        </div>
        <Flip top delay={3000}>
          <div className="backbtn">
            <button className="back buttons" onClick={() => navigate(-1)}>
              Back
            </button>
          </div>
        </Flip>
      </div>
    </Fragment>
  );
}

export default Sidemenu;
