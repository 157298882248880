import React, { Fragment } from 'react'
import CareerBanner from '../Components/Career/CareerBanner'

function Career() {
  return (
    <Fragment>
        <div className='container-fluid faderight '>
          <div className='row'>
            <CareerBanner/>
          </div>
        </div>
    </Fragment>
  )
}

export default Career